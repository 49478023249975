<template>
  <div
    class="bg-background-fg/7 inline-flex space-x-4 rounded-lg px-1 py-1"
  >
    <template v-for="option in options" :key="option.value">
      <button
        :class="[
          option.value === modelValue ? 'bg-white' : '',
          'rounded-md px-3 py-1',
        ]"
        @click="onClickOption(option.value)"
      >
        {{ option.label }}
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  modelValue: string | null
  options: Array<{
    label: string
    value: string | null
  }>
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: string | null): void
}>()

const onClickOption = (optionValue: string | null) =>
  emit("update:modelValue", optionValue)
</script>
