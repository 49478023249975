<template>
  <BaseInputField
    #default="{ inputProps, hasError }"
    :model-value="modelValue"
    :name="name"
    :optional="optional"
    :label="label"
    :disabled="disabled"
    :rules="rules"
    :hint="hint"
  >
    <div class="relative">
      <input
        v-bind="{ ...inputProps }"
        v-model="localModelValue"
        :type="type"
        class="input"
        :class="[
          $slots.after ? 'pr-10' : '',
          inputClass,
          hasError ? 'border-error' : '',
        ]"
        tabindex="0"
        :pattern="pattern"
        :placeholder="placeholder"
      />
      <template v-if="$slots.after">
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <slot name="after" />
        </div>
      </template>
      <slot />
    </div>
  </BaseInputField>
</template>

<script setup lang="ts">
// After vue 3.3 we can have props extend BaseInputFieldProps directly
// rather than copying over type definitions
const props = withDefaults(
  defineProps<{
    name?: string
    optional?: boolean
    label?: string
    disabled?: boolean
    rules?: Array<(value: string) => boolean | string>
    hint?: string
    modelValue: string
    inputClass?: string
    type?: string
    pattern?: string
    placeholder?: string
  }>(),
  {
    type: "text",
    inputClass: "",
  }
)

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void
}>()

const localModelValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})
</script>
