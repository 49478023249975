import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { BookingApiResponse } from "./bookings"
import type { MaybeRef } from "@vueuse/core"

export const retrieveBooking = async (
  bookingId: string
) => {
  const { body } = await api.get<BookingApiResponse>(
    `/bookings/${bookingId}`
  )
  return parseBookingApiResponse(body)
}

export const useBooking = (bookingId: MaybeRef<string>) =>
  useQuery({
    queryKey: [BOOKINGS_QUERY_KEY, bookingId],
    queryFn: ({ queryKey: [_prefix, filter_] }) =>
      retrieveBooking(unref(filter_)),

    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })
