import type { Modifier } from "@popperjs/core"

export const sameWidthModifier: Modifier<
  "sameWidth",
  object
> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    const referenceWidth =
      "offsetWidth" in state.elements.reference
        ? state.elements.reference.offsetWidth
        : 0
    state.elements.popper.style.width = `${referenceWidth}px`
  },
}
