import { api } from "@//api"
import { useInfiniteQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

export const ALERT_QUERY_KEY = "alerts"

type AlertFilters = Partial<{
  bookingId: string
  siteId: string | null
  customerId: string
  alertType: string
  seen: boolean
  from: Date
  to: Date
}>

type AlertQuery = AlertFilters & PaginationQuery

export type Alert = {
  id: string
  siteId: string
  bookingId: string
  customerId: string
  alertType: string
  isSeen: boolean
  title: string
  content: string
  createdAt: string
  href: string
}

export const listAlerts = async (query: AlertQuery) => {
  const { body } = await api.get<
    Paginate<Alert[]> & { unseenCount: number }
  >("/alerts", { query: query })

  return body
}

export const useAlerts = (
  query?: MaybeRef<AlertFilters>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useInfiniteQuery(
    [
      ALERT_QUERY_KEY,
      computed(() => ({
        siteId: currentSiteId.value,
        ...unref(query),
      })),
    ] as const,
    ({
      queryKey: [_prefix, filters],
      pageParam: pageParameter = 0,
    }) =>
      listAlerts({
        ...unref(filters),
        limit: 20,
        offset: pageParameter,
      }),
    {
      refetchIntervalInBackground: false,
      refetchInterval: 60 * 1000,
      getNextPageParam: (lastPage) => lastPage.nextOffset,
      getPreviousPageParam: (firstPage) =>
        firstPage.previousOffset,
    }
  )
}
