import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { BaseReportFilters } from "./reports"
import type { Ref } from "vue"

export const REPORT_SMS_QUERY_KEY = "sms-reports"

export type SmsDataRow = {
  siteName: string
  totalSent: number
  totalLocal: number
  totalInternational: number
  totalLocalCreditsUsed: number
  totalInternationalCreditsUsed: number
  totalSentPitch: number
  percentageSentPitch: string
  totalSentLanguage: number
  percentageSentLanguage: string
  totalSentReg: number
  percentageSentReg: string
  totalSmsAlert: number
  totalIncoming: number
  totalPitchResponse: number
  percentagePitchResponse: string
  totalRegResponse: number
  percentageRegResponse: string
  totalLanguageResponse: number
  percentageLanguageResponse: string
}

export type SmsData = SmsDataRow[]

export const retrieveSmsData = async (
  query: BaseReportFilters
) => {
  const { body } = await api.get<SmsData>(
    "/reports/sms/",
    { query: query }
  )

  return body
}

export const useSmsData = (
  filters: BaseReportFilters | Ref<BaseReportFilters>
) => {
  return useQuery({
    queryKey: [REPORT_SMS_QUERY_KEY, filters] as const,
    queryFn: ({ queryKey: [_, query] }) =>
      retrieveSmsData(unref(query)),
    staleTime: 60 * 60 * 1000,
  })
}

export const exportSmsData = async (
  query: BaseReportFilters
) => {
  const data = await api.get<Blob>("/reports/sms/export/data", {
    query: query,
  })

  return exportFileFromRespone(data)
}

export const exportSmsReport = async (
  query: BaseReportFilters
) => {
  const data = await api.get<Blob>("/reports/sms/export/report", {
    query: query,
  })

  return exportFileFromRespone(data)
}
