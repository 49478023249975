<template>
  <div class="w-full px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">
          ANPR Log
        </h1>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div
        class="mb-4 flex w-full flex-wrap items-center justify-between gap-2"
      >
        <BaseSegmentedControl
          v-model="localFilters.status"
          :options="statusFilterOptions"
        />
        <BaseDateIntervalSelect v-model="datePickerModel" />
      </div>
      <CameraEventsTable :filters="filters" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CameraEventStatusOptions } from "@/services/cameraEvents/listCameraEvents"

const localFilters = reactive({
  status: null,
  search: undefined,
  fromDt: DateFns.startOfToday(),
  untilDt: DateFns.endOfToday(),
})

const { datePickerModel } = useDatePickerContext()
const filters = reactiveComputed(() => ({
  ...localFilters,
  ...{
    fromDt: datePickerModel.value.start,
    untilDt: datePickerModel.value.end,
  },
}))

const statusFilterOptions = [
  { label: "All", value: null },
  ...CameraEventStatusOptions,
]
</script>
