<template>
  <div class="overflow-hidden">
    <div
      :class="[
        $style.grid,
        'grid min-w-full gap-2 overflow-x-auto pb-4',
      ]"
    >
      <template
        v-for="(row, siteName) in rows"
        :key="siteName"
      >
        <div class="flex items-center justify-end">
          <span
            class="text-background-fg/70 text-right text-xs font-medium"
          >
            {{ siteName }}
          </span>
        </div>
        <template v-for="item in row" :key="item.x">
          <div :class="[$style.squareHack]">
            <div
              :class="[
                'bg-primary border-1 border-primary text-background-fg/87 absolute inset-0  flex items-center justify-center font-medium',
                `bg-opacity-${getCellOpacity(
                  item.y,
                  siteName
                )}`,
              ]"
            >
              <div>
                <p class="mb-0 text-center text-lg">
                  {{ item.y }}
                </p>
                <template v-if="showPercentage && item.y">
                  <p
                    class="text-background-fg/70 text-center text-xs"
                  >
                    {{
                      formatToPercentage(
                        item.y / (rowTotals[siteName] || 1)
                      )
                    }}
                  </p>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
      <div class=""> </div>
      <template v-for="label in xLabels" :key="label">
        <div
          class="flex items-center justify-around overflow-hidden"
        >
          <span class="text-xs">
            {{ label }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
type RowKeyT = string | number
type XT = string | number | Date
type HeatmapChartRow = {
  x: XT
  y: number
}

const props = defineProps<{
  rows: Record<RowKeyT, HeatmapChartRow[]>
  showPercentage?: boolean
}>()

const rowMaxes = computed(() =>
  Object.fromEntries(
    Object.entries(props.rows).map(([siteName, row]) => [
      siteName,
      Math.max(...row.map(({ y }) => y)),
    ])
  )
)

const rowTotals = computed(() =>
  Object.fromEntries(
    Object.entries(props.rows).map(([siteName, row]) => [
      siteName,
      row.reduce(
        (accumulator, { y }) => accumulator + y,
        0
      ),
    ])
  )
)

const xLabels = computed(
  () =>
    Object.values(props.rows)?.[0]?.map(({ x }) => x) || []
)

const numberOfLabels = computed(
  () => xLabels.value?.length || 0
)

const getCellOpacity = (
  yValue: number,
  rowIndex: RowKeyT
) =>
  Math.ceil((yValue / rowMaxes.value[rowIndex]) * 20) * 5 ||
  0
</script>

<style lang="scss" module>
.squareHack {
  position: relative;
  padding-top: 100%;
}

.grid {
  grid-template-columns: 3fr repeat(
      v-bind("numberOfLabels"),
      1fr
    );
}
</style>
