<template>
  <div class="w-full px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">
          Reports
        </h1>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div
        class="mb-4 flex w-full flex-wrap items-center justify-between gap-2"
      >
        <div />
        <BaseDateIntervalSelect
          v-model="datePickerModel"
          disable-unbounded
        />
      </div>

      <div class="flex flex-col space-y-8">
        <div class="rounded-lg p-4 shadow">
          <h2
            class="text-background-fg/70 mb-4 text-lg font-medium"
          >
            Overview
          </h2>
          <BaseTable
            :is-loading="isOverviewReportLoading"
            key-field="siteName"
            :rows="overviewReportData || []"
            :columns="overviewReportColumns"
            class="mb-4"
          />

          <ButtonExport @click="onExportOverview">
            Export Overview Report
          </ButtonExport>

          <h2
            class="text-background-fg/70 mt-16 mb-4 text-lg font-medium"
          >
            Arrival Times
          </h2>
          <ChartHeatmap
            :rows="heatmapRows"
            class="mb-4"
            show-percentage
          />

          <ButtonExport @click="onExportArrivalTimes">
            Export Arrival Times Report
          </ButtonExport>
        </div>

        <div class="space-y-2 rounded-lg p-4 shadow">
          <h2
            class="text-background-fg/70 mb-2 text-lg font-medium"
          >
            ANPR
          </h2>
          <BaseTable
            :is-loading="isAnprReportLoading"
            key-field="siteName"
            :rows="anprReportData || []"
            :columns="anprReportColumns"
          />
          <ButtonExport @click="onExportAnpr">
            Export ANPR Report
          </ButtonExport>
        </div>

        <div class="space-y-2 rounded-lg p-4 shadow">
          <h2
            class="text-background-fg/70 mb-2 text-lg font-medium"
          >
            SMS
          </h2>
          <p class="text-background-fg/70 text-xs">
            This data reflects the numbers of sms messages
            sent/received for bookings that arrived within
            the selected date range
          </p>
          <BaseTable
            :is-loading="isSmsDataLoading"
            key-field="siteName"
            :rows="smsData || []"
            :columns="smsReportColumns"
          />
          <div class="flex">
            <ButtonExport @click="onExportSmsReport">
              Export SMS Report
            </ButtonExport>
            <ButtonExport class="text-background-fg/70" @click="onExportSmsData">
              Export SMS Data
            </ButtonExport>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseReportFilters } from "@/services/reports/reports"
import type { ColumnDefinition } from "@/types/table"
import type { AnprReportRow } from "@/services/reports/getAnprReport"
import type { SmsDataRow } from "@/services/reports/getSmsData"
import type { OverviewReportRow } from "@/services/reports/getOverviewReport"

const { id: currentSiteId } = useCurrentSite()

const { datePickerModel } = useDatePickerContext()
const filters = reactiveComputed<BaseReportFilters>(() => ({
  siteId: currentSiteId.value ?? undefined,
  ...{
    fromDt: datePickerModel.value.start,
    untilDt: datePickerModel.value.end,
  },
}))

const overviewReportColumns: ColumnDefinition<OverviewReportRow>[] =
  [
    {
      label: "Site",
      name: "site",
      getValue: (row) => row.siteName,
    },
    {
      label: "Arrivals",
      name: "arrivals",
      getValue: (row) => row.totalArrivals,
      align: "center",
    },
    {
      label: "ANPR Arrivals",
      name: "anprArrivals",
      getValue: (row) =>
        `${row.totalAnprArrivals} (${formatToPercentage(
          row.totalAnprArrivals / (row.totalArrivals || 1)
        )})`,
      align: "center",
    },
    {
      label: "Fast check-ins",
      name: "fastCheckIn",
      getValue: (row) =>
        `${row.totalFastCheckIn} (${formatToPercentage(
          row.totalFastCheckIn / (row.totalArrivals || 1)
        )})`,
      align: "center",
    },
    {
      label: "Manual check-ins",
      name: "manualCheckIns",
      getValue: (row) =>
        `${row.totalManualCheckIn} (${formatToPercentage(
          row.totalManualCheckIn / (row.totalArrivals || 1)
        )})`,
      align: "center",
    },
    {
      label: "Auto Pay",
      name: "autoPay",
      getValue: (row) => row.totalAutoPay,
      align: "center",
    },
    {
      label: "Arrivals w/o Paying",
      name: "arrivalsWithoutPaying",
      getValue: (row) => row.totalArrivalsWithoutPaying,
      align: "center",
    },
    {
      label: "Arrivals w/o Paying (fast check-in)",
      name: "arrivalsWithoutPayingFastCheckIn",
      getValue: (row) =>
        row.totalArrivalsWithoutPayingFastCheckIn,
      align: "center",
    },
    {
      label: "Paid in advance",
      name: "paidInAdvance",
      getValue: (row) => row.totalPaidInAdvance,
      align: "center",
    },
    {
      label: "Outside Entry Time Attempts",
      name: "outsideEntryTimeAttempts",
      getValue: (row) => row.totalOutsideEntryTimeAttempts,
      align: "center",
    },
  ]

const {
  data: overviewReportData,
  isFetching: isOverviewReportLoading,
} = useOverviewReport(filters)

const anprReportColumns: ColumnDefinition<AnprReportRow>[] =
  [
    {
      label: "Site",
      name: "site",
      getValue: (row) => row.siteName,
    },
    {
      label: "ANPR Reads",
      name: "anprReads",
      getValue: (row) => row.totalAnprReads,
      align: "center",
    },
    {
      label: "Barrier Opens",
      name: "barrierOpens",
      getValue: (row) => row.totalBarrierOpens,
      align: "center",
    },
    {
      label: "Fuzzy Matches",
      name: "fuzzyMatched",
      getValue: (row) =>
        `${row.totalFuzzyMatched} (${row.percentageFuzzyMatched})`,
      align: "center",
    },
    {
      label: "Denied",
      name: "denied",
      getValue: (row) => row.totalDenied,
      align: "center",
    },
  ]
const {
  data: anprReportData,
  isFetching: isAnprReportLoading,
} = useAnprReport(filters)

const smsReportColumns: ColumnDefinition<SmsDataRow>[] = [
  {
    label: "Site",
    name: "site",
    getValue: (row) => row.siteName,
  },
  {
    label: "Sent",
    name: "sent",
    getValue: (row) => row.totalSent,
    align: "center",
  },
  {
    label: "Local",
    name: "local",
    getValue: (row) => row.totalLocal,
    align: "center",
  },
  {
    label: "International",
    name: "international",
    getValue: (row) => row.totalInternational,
    align: "center",
  },
  {
    label: "Local Credits",
    name: "localCredits",
    getValue: (row) => row.totalLocalCreditsUsed,
    align: "center",
  },
  {
    label: "International Credits",
    name: "internationalCredits",
    getValue: (row) => row.totalInternationalCreditsUsed,
    align: "center",
  },
  {
    label: "Cost",
    name: "cost",
    getValue: (row) => {
      const credits =
        row.totalInternationalCreditsUsed +
        row.totalLocalCreditsUsed
      const costPerCredit = 0.035
      return `£${(credits * costPerCredit).toFixed(2)}`
    },
    align: "center",
  },
  {
    label: "Received",
    name: "incoming",
    getValue: (row) => row.totalIncoming,
    align: "center",
  },

  {
    label: "Pitch Response",
    name: "pitchResponse",
    getValue: (row) =>
      `${row.totalPitchResponse} (${row.percentagePitchResponse})`,
    align: "center",
  },
  {
    label: "Registration Response",
    name: "regResponse",
    getValue: (row) =>
      `${row.totalRegResponse} (${row.percentageRegResponse})`,
    align: "center",
  },
  {
    label: "Language Response",
    name: "languageResponse",
    getValue: (row) =>
      `${row.totalLanguageResponse} (${row.percentageLanguageResponse})`,
    align: "center",
  },
  {
    label: "Alerts",
    name: "smsAlerts",
    getValue: (row) => row.totalSmsAlert,
    align: "center",
  },
]
const {
  data: smsData,
  isFetching: isSmsDataLoading,
} = useSmsData(filters)

const onExportOverview = () => {
  exportOverviewReport(filters)
}

const onExportArrivalTimes = () => {
  exportArrivalTimeReport(filters)
}

const onExportAnpr = () => {
  exportAnprReport(filters)
}

const onExportSmsData = () => {
  exportSmsData(filters)
}

const onExportSmsReport = () => {
  exportSmsReport(filters)
}

const { data: arrivalTimeReportData } =
  useArrivalTimeReport(filters)

const heatmapRows = computed(() =>
  Object.fromEntries(
    Object.entries(
      groupBy(
        arrivalTimeReportData.value || [],
        (row) => row.siteName
      )
    ).map(([siteName, rows]) => [
      siteName,
      cleanHeatmapRow(
        rows.map(({ numberOfArrivals, startTime }) => ({
          x: startTime,
          y: numberOfArrivals,
        }))
      ),
    ])
  )
)

const cleanHeatmapRow = (
  row: { x: string; y: number }[]
) => {
  return Object.entries(
    row
      .sort((a, b) => a.x.localeCompare(b.x))
      .reduce((accumulator, { x, y }) => {
        const [h] = x.split(":")
        const ampm = Number(h) > 12 ? "pm" : "am"
        const hourNumber = Number(h) % 12 || 12
        if (Number(h) < 7) {
          return {
            ...accumulator,
            ["< 7am"]: accumulator["< 7am"]
              ? accumulator["< 7am"] + y
              : y,
          }
        } else if (Number(h) >= 21) {
          return {
            ...accumulator,
            ["> 9pm"]: accumulator["< 9pm"]
              ? accumulator["< 9pm"] + y
              : y,
          }
        } else {
          return {
            ...accumulator,
            [`${hourNumber}${ampm}`]: y,
          }
        }
      }, {} as Record<string, number>)
  ).map(([x, y]) => ({ x, y }))
}
</script>
