<template>
  <div
    class="bg-primary/20 relative h-0.5 w-full overflow-hidden"
  >
    <div
      class="bg-primary absolute h-full w-1/4"
      :class="$style.loadingBar"
    />
  </div>
</template>

<style lang="scss" module>
.loadingBar {
  animation: slide 5s infinite linear;
}

@keyframes slide {
  from {
    left: -25%;
  }

  to {
    left: 100%;
  }
}
</style>
