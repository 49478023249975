import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { BaseReportFilters } from "./reports"
import type { Ref } from "vue"

export const REPORT_ARRIVAL_TIME_QUERY_KEY =
  "arrival-times-report"

export type ArrivalTimeReportRow = {
  siteName: string
  startTime: string
  numberOfArrivals: number
}

export type ArrivalTimeReport = ArrivalTimeReportRow[]

export const retrieveArrivalTimeReport = async (
  query: BaseReportFilters
) => {
  const { body } = await api.get<ArrivalTimeReport>(
    "/reports/arrival-times/",
    { query: query }
  )

  return body
}

export const useArrivalTimeReport = (
  filters: BaseReportFilters | Ref<BaseReportFilters>
) => {
  return useQuery({
    queryKey: [
      REPORT_ARRIVAL_TIME_QUERY_KEY,
      filters,
    ] as const,
    queryFn: ({ queryKey: [_, query] }) =>
      retrieveArrivalTimeReport(unref(query)),
    staleTime: 60 * 60 * 1000,
  })
}

export const exportArrivalTimeReport = async (
  query: BaseReportFilters
) => {
  const data = await api.get<Blob>(
    "/reports/arrival-times/export/",
    { query: query }
  )

  return exportFileFromRespone(data)
}
