<template>
  <div>
    <section class="space-y-4">
      <div class="flex items-center space-x-10">
        <div class="w-100">
          <p class="text-background-fg/80 text-sm">
            Set visitor as arrived
          </p>
          <p class="text-background-fg/50 text-xs">
            This will set the arrival time to now, and send
            out the arrival SMSs.
          </p>
        </div>

        <button
          class="ring-secondary text-secondary hover:bg-secondary/10 rounded py-1 px-2 ring-2 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-inherit"
          type="button"
          :disabled="!!booking?.arrivedAt"
          @click="handleCheckInClick"
        >
          Set Arrived
        </button>
      </div>

      <div class="flex items-center space-x-10">
        <div class="w-100">
          <p class="text-background-fg/80 text-sm">
            Delete this booking
          </p>
          <p class="text-background-fg/50 text-xs">
            This will delete the booking (reference shown
            above). This action cannot be undone.
          </p>
        </div>

        <button
          class="ring-error text-error hover:bg-error/10 rounded py-1 px-2 ring-2 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-inherit"
          type="button"
          @click="handleDeleteClick"
        >
          Delete Booking
        </button>
      </div>
    </section>
  </div>

  <BaseConfirmation
    ref="checkinConfirmDialogRef"
    description="Are you sure you wish to check this booking in?"
    confirm-label="Check In"
    cancel-label="Cancel"
    type="positive"
  />

  <BaseConfirmation
    ref="deleteConfirmDialogRef"
    description="Are you sure you wish to delete this booking?"
    confirm-label="Delete Booking"
    cancel-label="Cancel"
  />
</template>

<script setup lang="ts">
import type { Booking } from "@/services/bookings/bookings"
import type BaseConfirmationVue from "./BaseConfirmation.vue"

const props = defineProps<{
  booking: Booking
}>()

const router = useRouter()
const deleteConfirmDialogRef = ref<InstanceType<
  typeof BaseConfirmationVue
> | null>(null)

const isDeleting = ref(false)
const handleDeleteClick = async () => {
  if (deleteConfirmDialogRef.value === null) return

  const { isCanceled: isDeleteCancelled } =
    await deleteConfirmDialogRef.value.reveal()

  if (isDeleteCancelled) return

  isDeleting.value = true
  try {
    await deleteBooking(props.booking.id)
    toast.success("Booking deleted")
    router.push({
      name: "bookings",
    })
  } catch (e) {
    toast.error("Failed to delete booking")
    throw e
  } finally {
    isDeleting.value = false
  }
}

const checkinConfirmDialogRef = ref<InstanceType<
  typeof BaseConfirmationVue
> | null>(null)
const isCheckingIn = ref(false)
const handleCheckInClick = async () => {
  if (checkinConfirmDialogRef.value === null) return

  const { isCanceled: isCheckinCancelled } =
    await checkinConfirmDialogRef.value.reveal()
  if (isCheckinCancelled) return

  isCheckingIn.value = true
  try {
    await checkInBooking(props.booking.id)
    toast.success("Booking checked in")
  } catch (e) {
    toast.error("Failed to check in booking")
    throw e
  } finally {
    isCheckingIn.value = false
  }
}
</script>
