<template>
  <span class="inline-flex flex-col items-center">
    <p class="text-background-fg/87 font-semibold">
      {{ formattedTimePart }}
    </p>
    <p>
      {{ formattedDatePart }}
    </p>
  </span>
</template>

<script setup lang="ts">
const props = defineProps<{ value: Date | string }>()

const formattedDatePart = computed(() =>
  formatDatetimeToDatePart(props.value)
)
const formattedTimePart = computed(() =>
  formatDatetimeToTimePart(props.value)
)
</script>
