import type { FunctionalComponent } from "vue"
import type { RouteLocationRaw } from "vue-router"
import { RouterLink } from "vue-router"

type Props = {
  isExternal?: boolean
  disabled?: boolean
  to: RouteLocationRaw
}

export const TurboRouterLink: FunctionalComponent<Props> = (
  props,
  context
) => {
  return props.disabled
    ? renderDisabledLinkWrapper(props, context)
    : props.to
    ? props.isExternal
      ? renderLinkWrapper(props, context)
      : renderRouterLink(props, context)
    : renderButtonWrapper(props, context)
}

TurboRouterLink.props = ["isExternal", "disabled", "to"] // Need to be declared to normalize props to camelcase

const renderDisabledLinkWrapper: FunctionalComponent<
  Props
> = (props, { slots }) =>
  slots.default?.({
    component: "button",
    href: undefined,
    isActive: false,
    isExactActive: false,
    onClick: undefined,
    disabled: true,
  })

const renderLinkWrapper: FunctionalComponent<Props> = (
  props,
  { slots }
) =>
  slots.default?.({
    component: "a",
    href: props.to,
    isActive: false,
    isExactActive: false,
    onClick: undefined,
  })

const renderButtonWrapper: FunctionalComponent<Props> = (
  props,
  { slots, attrs }
) =>
  slots.default?.({
    component: "button",
    href: undefined,
    isActive: false,
    isExactActive: false,
    onClick: attrs.onClick,
  })

type RouterLinkSlotProps = Parameters<
  NonNullable<
    InstanceType<typeof RouterLink>["$slots"]["default"]
  >
>

const renderRouterLink: FunctionalComponent<Props> = (
  { disabled, isExternal, ...props },
  { slots }
) =>
  h(
    RouterLink,
    { ...props, custom: true },
    {
      default: (
        routerLinkSlotProps: RouterLinkSlotProps[0]
      ) =>
        slots.default?.({
          ...routerLinkSlotProps,
          component: "a",
          onClick: routerLinkSlotProps.navigate,
        }),
    }
  )
