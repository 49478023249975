import { QueryClient } from "@tanstack/vue-query"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 60 * 1000,
      // refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  },
})
