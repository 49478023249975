<template>
  <div class="h-full">
    <RouterView v-if="isReady" />
    <div v-else class="relative h-full w-full">
      <BaseContainerLoading />
    </div>
    <DialogsWrapper />
  </div>
</template>

<script setup lang="ts">
import { DialogsWrapper } from "vuejs-confirm-dialog"

const { refreshToken, logOut, isLoggedIn } = useAuth()
const isReady = ref(false)
const router = useRouter()
const { hydrateCurrentUser, clearCurrentUser } =
  useCurrentUser()

onMounted(async () => {
  if (refreshToken.value) {
    try {
      await refreshAccessToken()
    } catch {
      logOut()
      await router.push({ name: "logIn" })
    }
  }

  isReady.value = true
})

watch(
  isLoggedIn,
  async (newIsLoggedIn) => {
    if (newIsLoggedIn) {
      hydrateCurrentUser()
    } else {
      clearCurrentUser()
    }
  },
  {
    immediate: true,
  }
)
</script>

<style lang="scss">
@import "@/styles/inter.scss";

:root {
  --color-primary: 210 94 21;
  --color-on-primary: 255 255 255;
  --color-secondary: 0 149 144;
  --color-on-secondary: 255 255 255;
  --color-background: 255 255 255;
  --color-on-background: 0 0 0;
}
</style>
