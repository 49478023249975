type Predicate<T> = (
  argument0: T
) => string | number | symbol

export const groupBy = <T>(
  array: T[],
  predicate: Predicate<T>
) =>
  array.reduce(
    (
      accumulator: Record<
        ReturnType<typeof predicate>,
        T[]
      >,
      value
    ) => {
      const key = predicate(value)

      return {
        ...accumulator,
        [key]: accumulator[key]
          ? [...accumulator[key], value]
          : [value],
      }
    },
    {}
  )
