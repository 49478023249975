import { api } from "@/api"

type UserId = string

interface User {
  id: UserId
  email: string
  firstName: string
  lastName: string
  isOwner: boolean
  phone: string
  photo: string
  siteId: string | null
}

const currentUser = ref<User | null>(null)

const hydrateCurrentUser = async () => {
  try {
    const { body } = await api.get<User>("/users/self")
    currentUser.value = body
  } catch (error) {
    currentUser.value = null
    throw error
  }
}

const clearCurrentUser = () => {
  currentUser.value = null
}

export const useCurrentUser = () => ({
  currentUser: readonly(currentUser),
  hydrateCurrentUser,
  clearCurrentUser,
})
