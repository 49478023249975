const refreshToken = useLocalStorage("refreshToken", "")
const setRefreshToken = (newToken: string) => {
  refreshToken.value = newToken
}

const accessToken = ref<string | undefined>()
const setAccessToken = (newToken: string) => {
  accessToken.value = newToken
}

const isLoggedIn = computed(() => !!accessToken.value)

const logOut = () => {
  refreshToken.value = undefined
  accessToken.value = undefined
}

export const useAuth = () => {
  return {
    accessToken,
    setAccessToken,
    logOut,
    isLoggedIn,
    refreshToken,
    setRefreshToken,
  }
}
