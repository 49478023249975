import App from "@/App.vue"
import router from "./router"
import { VueQueryPlugin } from "@tanstack/vue-query"
import { queryClient } from "@/queryClient"

import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

import "virtual:windi-base.css"

import "virtual:windi-components.css"
import "@/styles/components.scss"

import "virtual:windi-utilities.css"

import "virtual:windi-devtools"

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://162d0a06773f49e5828d7cab36068cf4@o1338945.ingest.sentry.io/6688447",
    integrations: [
      new BrowserTracing({
        routingInstrumentation:
          Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "camc.visitor.express",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1,
  })
}

app.use(VueQueryPlugin, { queryClient })

app.use(router)

app.mount("#app")

export default app
