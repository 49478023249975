<template>
  <form novalidate @submit.prevent="onSubmit">
    <slot />
  </form>
</template>

<script setup lang="ts">
import {
  FORM_CONTEXT_INJECTION_KEY,
  type FormField,
  type FormContext,
  type FormSubmitHelpers,
} from "@/composables/useFormContext"

const emit = defineEmits<{
  (event: "submit", helpers: FormSubmitHelpers): void
}>()

const fields = reactive(new Map<string, FormField>())

const formContext: FormContext = {
  registerField: (fieldName, field) =>
    fields.set(fieldName, field),
  unregisterField: (fieldName) => fields.delete(fieldName),
}

const validateFields = () => {
  for (const { resetValidation } of fields.values()) {
    resetValidation()
  }

  const valid = [...fields.values()].reduce(
    (accumulator, { validate }) =>
      validate() && accumulator,
    true
  )
  return valid
}

const setErrors = (errors: Record<string, string>) => {
  for (const [key, value] of Object.entries(errors)) {
    fields.get(key)?.setError(value)
  }
}

const onSubmit = () => {
  const allAreValid = validateFields()

  if (allAreValid) {
    emit("submit", { setErrors })
  }
}

provide(FORM_CONTEXT_INJECTION_KEY, formContext)
</script>
