import humps from "humps"
import qs from "qs"

interface BuildQueryStringOptions {
  includeKeys?: string[]
}

const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b)
}

export const buildQueryString = (
  queryObject: object,
  { includeKeys }: BuildQueryStringOptions = {}
) => {
  if (!queryObject) return ""
  const searchParams = qs.stringify(
    humps.decamelizeKeys(queryObject),
    {
      arrayFormat: "repeat",
      addQueryPrefix: true,
      sort: alphabeticalSort,
      ...(includeKeys && { filter: includeKeys }),
      skipNulls: true,
    }
  )

  return searchParams || ""
}
