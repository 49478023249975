import BaseNotification from "./BaseNotification.vue"

const toast_ = ({
  message,
  type,
}: {
  message: string
  type: string
}) =>
  mountComponent(BaseNotification, {
    props: { message, type, timeout: 5000 },
  })

export const toast = {
  error: (message: string) =>
    toast_({ message, type: "error" }),
  success: (message: string) =>
    toast_({ message, type: "success" }),
  info: (message: string) =>
    toast_({ message, type: "info" }),
}
