import { api } from "@//api"
import {
  type Booking,
  type BookingApiResponse,
  parseBookingApiResponse,
} from "./bookings"
import type { CreateUpdateBookingRequestBody } from "./createUpdateBookings"
import { queryClient } from "@/queryClient"
import { parseBookingForm } from "./createUpdateBookings"
import type { BookingForm } from "./bookingForm"

type CreateBookingRequest = CreateUpdateBookingRequestBody

export const createBooking = async (
  formValues: BookingForm
): Promise<Booking> => {
  const requestBody: CreateBookingRequest =
    parseBookingForm(formValues)

  const { body: responseBody } =
    await api.post<BookingApiResponse>("/bookings/", {
      body: requestBody,
    })

  queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])

  return parseBookingApiResponse(responseBody)
}
