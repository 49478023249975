import { api } from "@//api"

export const sendBookingSms = async (
  bookingId: string,
  content = ""
) => {
  return await api.post(
    `/bookings/${bookingId}/send-sms/`,
    { body: { content } }
  )
}
