export const formatDatetime = (
  datetime: Date | string,
  formatString: string
) => {
  if (!datetime) return ""
  if (datetime instanceof Date)
    return DateFns.format(datetime, formatString)
  try {
    return DateFns.format(
      DateFns.parseISO(datetime),
      formatString
    )
  } catch {
    return ""
  }
}
