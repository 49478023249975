export const formatToCurrencyString = (
  price: number,
  currency = "GBP"
): string => {
  const localeBcp47 = "en-GB"
  return new Intl.NumberFormat(localeBcp47, {
    style: "currency",
    currency: currency,
  }).format(price / 100)
}
