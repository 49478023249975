import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

export const CAMERA_QUERY_KEY = "cameras"

export type Barrier = {
  id: string
  name: string
  open?: boolean
}

export type Camera = {
  id: string
  displayName: string
  barrier?: Barrier
  liveFeedUrl?: string
}

type CameraQuery = {
  siteId: string | null
}

const listCameras = async (query: CameraQuery) => {
  const { body } = await api.get<Camera[]>(
    "/camera/cameras",
    {
      query,
    }
  )

  return body
}

export const useCameras = (
  query?: MaybeRef<CameraQuery>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      CAMERA_QUERY_KEY,
      computed(() => ({
        ...unref(query),
        siteId: currentSiteId.value,
      })),
    ] as const,
    queryFn: ({ queryKey: [_test, query_] }) =>
      listCameras(unref(query_)),
    staleTime: 60 * 60 * 1000,
  })
}
