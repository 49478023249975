<template>
  <HeadlessPopover as="div" class="relative ml-6">
    <HeadlessPopoverButton
      ref="popperTargetRef"
      href="#"
      class="text-background-fg/50 relative ml-5 flex flex-shrink-0 items-center justify-center rounded-full bg-white p-1 hover:text-opacity-80 focus:outline-none"
    >
      <IconHeroiconsSolidVideoCamera />
    </HeadlessPopoverButton>

    <Transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <HeadlessPopoverPanel
        ref="scrollContainerRef"
        class="ring-background-fg bg-background z-2 absolute right-0 mt-2 origin-top-right overflow-auto rounded-md p-4 shadow-lg ring-1 ring-opacity-5 focus:outline-none"
      >
        <BaseTabs
          v-model="activeCameraId"
          :tabs="cameraTabs"
        >
        </BaseTabs>
        <div>
          <template v-if="activeCamera">
            <CameraStreamTab :camera="activeCamera" />
          </template>
        </div>
      </HeadlessPopoverPanel>
    </Transition>
  </HeadlessPopover>
</template>

<script setup lang="ts">
const { data: cameras } = useCameras()

const activeCameraId = ref<string | null>(null)
const activeCamera = computed(() =>
  cameras.value?.find(
    ({ id }) => id === activeCameraId.value
  )
)

const cameraTabs = computed(
  () =>
    cameras.value?.map(({ id, displayName }) => ({
      id,
      label: displayName,
    })) ?? []
)
</script>
