import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { BookingApiResponse } from "./bookings"
import type { MaybeRef } from "@vueuse/core"

export type BookingStatus =
  | "arrived"
  | "checkedin"
  | "scheduled"
  | "expected"
  | "noshow"
  | "unknown"

const listBookingsSortbyOptions = [
  "customer_name",
  "-customer_name",
  "checked_in_at",
  "-checked_in_at",
  "auto_balance",
  "-auto_balance",
] as const

export type ListBookingsSortbyOption =
  (typeof listBookingsSortbyOptions)[number]

export const isListBookingsSortbyOption = (
  value: unknown
): value is ListBookingsSortbyOption =>
  listBookingsSortbyOptions.includes(
    value as ListBookingsSortbyOption
  )

type ListBookingsFilter = Partial<{
  siteId: string | null
  fromDt: Date
  untilDt: Date
  search: string
  paid: boolean
  status: BookingStatus | null
  sortBy: ListBookingsSortbyOption | null
  abf?: ("noabf" | "abf")[]
}>

export type ListBookingsQuery = ListBookingsFilter &
  PaginationQuery

export const listBookings = async (
  query: ListBookingsQuery
) => {
  const { body } = await api.get<
    Paginate<BookingApiResponse[]>
  >("/bookings", { query })

  return {
    ...body,
    items: body.items.map((booking) =>
      parseBookingApiResponse(booking)
    ),
  }
}

export const useBookings = (
  query: MaybeRef<ListBookingsQuery>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      BOOKINGS_QUERY_KEY,
      computed(() => ({
        ...unref(query),
        siteId: currentSiteId.value,
      })),
    ] as const,
    queryFn: ({ queryKey: [_prefix, filter_] }) =>
      listBookings(unref(filter_)),

    refetchIntervalInBackground: false,
    refetchInterval: 60 * 1000,
  })
}
