<template>
  <HeadlessDisclosure
    #default="{ open }"
    as="nav"
    class="z-1 bg-background shadow-lg shadow-black/10"
  >
    <div class="max-w-10xl mx-auto px-4 sm:px-6 xl:px-8">
      <div class="min-h-16 flex justify-between">
        <div class="flex">
          <div
            class="-mr-2 ml-auto flex items-center xl:hidden"
          >
            <!-- Mobile menu button -->
            <HeadlessDisclosureButton
              class="text-background-fg/50 hover:bg-background-fg/5 inline-flex items-center justify-center rounded-md bg-white p-2 hover:text-opacity-60"
            >
              <span class="sr-only">Open main menu</span>
              <IconHeroiconsSolidMenu
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <IconHeroiconsSolidX
                v-else
                class="block h-6 w-6"
                aria-hidden="true"
              />
            </HeadlessDisclosureButton>
          </div>
          <div class="ml-4 flex flex-shrink-0 items-center">
            <RouterLink
              :to="{ name: 'home' }"
              class="my-3 inline-flex items-center"
            >
              <IconCamcCamcLogo
                class="hidden h-10 w-auto sm:block"
              />

              <IconCamcCamcLogo
                class="h-8 w-auto sm:hidden"
              />
            </RouterLink>
          </div>

          <div
            class="hidden xl:-my-px xl:ml-6 xl:flex xl:space-x-8"
          >
            <template
              v-for="route in routes"
              :key="route.label"
            >
              <TurboRouterLink
                #default="{
                  href,
                  onClick,
                  isActive,
                  component,
                  disabled,
                }"
                :to="route.to"
              >
                <Component
                  :is="component"
                  :disabled="disabled"
                  :href="href"
                  :class="[
                    'disabled:border-transparent disabled:opacity-40',
                    isActive
                      ? 'border-primary'
                      : 'text-background-fg/40 hover:border-background-fg/50 border-transparent hover:text-opacity-50',
                    'border-b-3 inline-flex items-center px-3 pt-1 text-sm font-medium',
                  ]"
                  :aria-current="
                    isActive ? 'page' : undefined
                  "
                  @click="onClick"
                >
                  <Component
                    :is="route.icon"
                    class="mr-2"
                  />
                  {{ route.label }}
                </Component>
              </TurboRouterLink>
            </template>
          </div>
        </div>

        <div
          class="hidden space-x-2 xl:ml-6 xl:flex xl:items-center"
        >
          <CameraStreamPopover />
          <HeaderAlerts />
          <HeaderSiteDropdown class="w-48" />
          <HeadlessMenu as="div" class="relative">
            <div class="flex w-48 justify-end">
              <HeadlessMenuButton
                class="text-background-fg/60 flex w-48 items-center justify-between rounded-full py-2 px-3 text-base"
              >
                <span class="sr-only">Open user menu</span>
                <span class="line-clamp-1">
                  {{ currentUser?.email }}
                </span>
                <IconHeroiconsSolidChevronDown
                  class="ml-1 h-6 w-6"
                />
              </HeadlessMenuButton>
            </div>
            <Transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <HeadlessMenuItems
                class="ring-background-fg bg-background absolute right-0 mt-2 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none"
              >
                <HeadlessMenuItem>
                  <button
                    :class="[
                      'text-background-fg/70 block w-full px-4 py-2 text-left text-sm font-medium',
                    ]"
                    @click="logOut"
                  >
                    Log Out
                  </button>
                </HeadlessMenuItem>
              </HeadlessMenuItems>
            </Transition>
          </HeadlessMenu>

          <RouterLink
            :to="{ name: 'bookingCreate' }"
            class="bg-primary text-primary-fg rounded-full px-3 py-2 text-center text-sm"
          >
            Create Booking
          </RouterLink>
        </div>
        <div class="my-auto px-2 xl:hidden">
          <HeaderAlerts />
        </div>
      </div>
    </div>
    <HeadlessDisclosurePanel class="xl:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <template
          v-for="route in routes"
          :key="route.label"
        >
          <TurboRouterLink
            #default="{
              href,
              onClick,
              isActive,
              component,
              disabled,
            }"
            :to="route.to"
          >
            <HeadlessDisclosureButton
              :as="component"
              :disabled="disabled"
              :href="href"
              :class="[
                'disabled:border-none disabled:opacity-40',
                isActive
                  ? 'text-primary border-primary bg-primary/10'
                  : 'text-background-fg/60 hover:border-background-fg/30 hover:bg-background-fg/10 hover:text-background-fg/80 border-transparent',
                'block w-full border-l-4 py-2 pl-3 pr-4 text-left text-base font-medium',
              ]"
              :aria-current="isActive ? 'page' : undefined"
              @click="onClick"
            >
              {{ route.label }}
            </HeadlessDisclosureButton>
          </TurboRouterLink>
        </template>
        <TurboRouterLink
          #default="{
            href,
            onClick,
            isActive,
            component,
            disabled,
          }"
          :to="{ name: 'bookingCreate' }"
        >
          <HeadlessDisclosureButton
            :as="component"
            :disabled="disabled"
            :href="href"
            :class="[
              'disabled:border-none disabled:opacity-40',
              isActive
                ? 'text-primary border-primary bg-primary/10'
                : 'text-background-fg/60 hover:border-background-fg/30 hover:bg-background-fg/10 hover:text-background-fg/80 border-transparent',
              'block w-full border-l-4 py-2 pl-3 pr-4 text-left text-base font-medium',
            ]"
            :aria-current="isActive ? 'page' : undefined"
            @click="onClick"
          >
            Create Booking
          </HeadlessDisclosureButton>
        </TurboRouterLink>
      </div>
      <div
        class="border-background-fg/20 border-t pt-4 pb-3"
      >
        <div class="mt-3 space-y-1">
          <HeaderSiteDropdown class="w-full px-3" />
        </div>
      </div>

      <div
        class="border-background-fg/20 border-t pt-4 pb-3"
      >
        <div class="flex items-center px-4">
          <div class="">
            <div
              class="text-background-fg/80 text-sm font-medium"
              >{{ currentUser?.email }}</div
            >
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <HeadlessDisclosureButton
            as="button"
            class="text-background-fg/50 hover:bg-background-fg/10 hover:text-background-fg/80 block w-full px-4 py-2 text-left text-base font-medium"
            @click="logOut"
          >
            Log Out
          </HeadlessDisclosureButton>
        </div>
      </div>
    </HeadlessDisclosurePanel>
  </HeadlessDisclosure>
</template>

<script setup lang="ts">
import { TurboRouterLink } from "@/components/util/TurboRouterLink"
import type { Component } from "vue"
import type { RouteLocationNamedRaw } from "vue-router"
import IconHeroiconsSolidIdentification from "~icons/heroicons-solid/identification"
import IconHeroiconsSolidKey from "~icons/heroicons-solid/key"
import IconHeroiconsSolidVideoCamera from "~icons/heroicons-solid/video-camera"
import IconHeroiconsSolidChartBar from "~icons/heroicons-solid/chart-bar"
const { currentUser } = useCurrentUser()
const { logOut } = useAuth()

type NavHeaderRoute = {
  label: string
  to: RouteLocationNamedRaw
  icon: Component
}

const routes: Array<NavHeaderRoute> = [
  {
    label: "Bookings",
    to: {
      name: "bookings",
    },
    icon: IconHeroiconsSolidIdentification,
  },

  {
    label: "ANPR Log",
    to: {
      name: "cameraEvents",
    },
    icon: IconHeroiconsSolidVideoCamera,
  },
  {
    label: "Allowlist",
    to: {
      name: "allowlist",
    },
    icon: IconHeroiconsSolidKey,
  },
  {
    label: "Reports",
    to: {
      name: "reports",
    },
    icon: IconHeroiconsSolidChartBar,
  },
]
</script>
