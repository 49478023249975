<template>
  <div
    class="bg-background flex h-full flex-col overflow-hidden"
    :style="
      site?.isTraining && {
        '--color-background': '240 240 255',
      }
    "
  >
    <header
      v-if="site?.isTraining"
      class="bg-blue/90 flex items-center px-4 py-2 font-medium text-white"
    >
      <IconHeroiconsSolidExclamationCircle
        class="size-6 mr-2"
      />
      This is a training site.
    </header>
    <BaseLayoutMainNavHeader />
    <main
      class="flex-grow-1 h-full overflow-y-auto overflow-x-hidden"
    >
      <div
        class="max-w-10xl mx-auto min-h-full py-6 sm:px-6 lg:px-8"
      >
        <RouterView />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
const { isLoggedIn } = useAuth()

whenever(
  () => !isLoggedIn.value,
  () => router.push({ name: "logIn" }),
  { immediate: true }
)

const { id } = useCurrentSite()
const { data: sites } = useSites()
const site = computed(() =>
  sites.value?.find((site) => site.id === id.value)
)
</script>
