<template>
  <div
    class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <IconCamcCamcLogo
        class="mx-auto hidden h-10 w-auto sm:block"
      />
      <h2
        class="mt-6 text-center text-3xl font-extrabold text-gray-900"
      >
        Log In
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div
        class="relative bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
      >
        <BaseForm class="space-y-6" @submit="onFormSubmit">
          <template v-if="errorMessage">
            <p
              class="bg-error/10 text-error rounded px-2 py-1 text-sm"
            >
              {{ errorMessage }}
            </p>
          </template>

          <BaseInputText
            v-model="formValues.email"
            label="Email address"
          />

          <BaseInputPassword
            v-model="formValues.password"
            label="Password"
          />

          <div>
            <button
              type="submit"
              class="bg-primary text-primary-fg hover:bg-primary/70 focus:ring-primary flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Sign in
            </button>
          </div>
        </BaseForm>

        <template v-if="isLoggingIn">
          <BaseContainerLoading />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApiError } from "@/api"

const { isLoggedIn } = useAuth()

const router = useRouter()

const formValues = ref({
  email: "",
  password: "",
})

const errorMessage = ref<string | null>(null)
const isLoggingIn = ref(false)
const onFormSubmit = async () => {
  isLoggingIn.value = true
  errorMessage.value = null

  try {
    await logIn(formValues.value)
  } catch (error) {
    if (error instanceof ApiError && error.status === 401) {
      errorMessage.value = "Invalid log in credentials"
    }
  } finally {
    isLoggingIn.value = false
  }
}

whenever(
  () => isLoggedIn.value,
  () => router.push({ name: "home" }),
  { immediate: true }
)
</script>
