<template>
  <nav
    class="relative z-0 inline-flex items-center space-x-4 rounded-md"
    aria-label="Pagination"
  >
    <button
      href="#"
      class="text-background-fg/70 hover:bg-background-fg/3 relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-medium"
      @click="onPrevious"
    >
      <span class="sr-only">Previous</span>
      <IconHeroiconsSolidChevronLeft
        class="h-5 w-5"
        aria-hidden="true"
      />
    </button>
    <div class="text-background-fg/70 text-sm">
      page {{ modelValue }} of {{ pageCount }}
    </div>
    <button
      class="text-background-fg/70 bg-background hover:bg-background-fg/3 relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-medium"
      @click="onNext"
    >
      <span class="sr-only">Next</span>
      <IconHeroiconsSolidChevronRight
        class="h-5 w-5"
        aria-hidden="true"
      />
    </button>
  </nav>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    pageCount?: number
    modelValue?: number
  }>(),
  {
    pageCount: 0,
    modelValue: 1,
  }
)

const emit = defineEmits<{
  (event: "update:modelValue", value: number): void
}>()

const onNext = () => {
  emit(
    "update:modelValue",
    Math.min(props.modelValue + 1, props.pageCount)
  )
}
const onPrevious = () => {
  emit(
    "update:modelValue",
    Math.max(props.modelValue - 1, 1)
  )
}
</script>
