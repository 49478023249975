import { api } from "@/api.js"
import type { Language } from "@/services/languages/languages"
import type { MaybeRef } from "@vueuse/core"
export const CUSTOMER_QUERY_KEY = "customers"
import { useInfiniteQuery } from "@tanstack/vue-query"
// Keep in sync with CustomerSchema
export type Customer = {
  id: string
  title: string
  firstName: string
  lastName: string
  email: string | null
  phone: string | null
  membershipNumber: string | null
  membershipActive: boolean
  language: Language
}

// Keep in sync with CustomerInSchema
export type CreateUpdateBookingCustomer = {
  title: string
  firstName: string
  lastName: string
  email: string | null
  phone: string | null
  membershipNumber: string | null
  membershipActive: boolean | null
  language: Language
}

export type CustomerFilters = Partial<{
  search: string
}>
export type CustomerQuery = CustomerFilters &
  PaginationQuery

export const createCustomer = async (
  body: CreateUpdateBookingCustomer
) => {
  const res = await api.post<Customer>("/customers", {
    body,
  })
  return res.body
}

export const updateCustomer = async (
  id: string,
  body: CreateUpdateBookingCustomer
) => {
  const res = await api.put<Customer>(`/customers/${id}`, {
    body,
  })
  return res.body
}

export const searchCustomers = async (search: string) => {
  const body = await listBookings({
    search,
    limit: 20,
    offset: 0,
  })
  return body.items
}

export const listCustomers = async (
  query: CustomerQuery
) => {
  const res = await api.get<Paginate<Customer[]>>(
    "/customers",
    {
      query,
    }
  )
  return res.body
}

export const useInfiniteCustomers = (
  filters: MaybeRef<CustomerFilters>
) => {
  return useInfiniteQuery({
    queryKey: [CUSTOMER_QUERY_KEY, filters] as const,
    queryFn: ({
      queryKey: [_prefix, filters_],
      pageParam: pageParameter = 0,
    }) =>
      listCustomers({
        ...filters_,
        limit: 20,
        offset: pageParameter,
      }),
    refetchIntervalInBackground: false,
    refetchInterval: 60 * 1000,
    getNextPageParam: (lastPage) => lastPage.nextOffset,
    getPreviousPageParam: (firstPage) =>
      firstPage.previousOffset,
  })
}
