import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { AllowlistEntry } from "./allowlistEntries"
import type { MaybeRef } from "@vueuse/core"

export const retrieveAllowlistEntry = async (
  allowlistEntryId: string
) => {
  const { body } = await api.get<
    RecursivelyConvertDatesToStrings<AllowlistEntry>
  >(`/allowlist/${allowlistEntryId}`)
  return parseAllowlistEntry(body)
}

export const useAllowlistEntry = (
  allowlistEntryId: MaybeRef<string>
) =>
  useQuery({
    queryKey: [
      ALLOWLIST_ENTRIES_QUERY_KEY,
      allowlistEntryId,
    ],
    queryFn: ({ queryKey: [_prefix, filter_] }) =>
      retrieveAllowlistEntry(unref(filter_)),

    refetchInterval: 0,
    refetchOnWindowFocus: false,
  })
