import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { BaseReportFilters } from "./reports"
import type { Ref } from "vue"

export const REPORT_ANPR_QUERY_KEY = "anpr-reports"

export type AnprReportRow = {
  siteName: string
  totalAnprReads: number
  totalBarrierOpens: number
  totalFuzzyMatched: number
  percentageFuzzyMatched: string
  totalDenied: number
}

export type AnprReport = AnprReportRow[]

export const retrieveAnprReport = async (
  query: BaseReportFilters
) => {
  const { body } = await api.get<AnprReport>(
    "/reports/anpr/",
    { query: query }
  )

  return body
}

export const useAnprReport = (
  filters: BaseReportFilters | Ref<BaseReportFilters>
) => {
  return useQuery({
    queryKey: [REPORT_ANPR_QUERY_KEY, filters] as const,
    queryFn: ({ queryKey: [_, query] }) =>
      retrieveAnprReport(unref(query)),
    staleTime: 60 * 60 * 1000,
  })
}

export const exportAnprReport = async (
  query: BaseReportFilters
) => {
  const data = await api.get<Blob>(
    "/reports/anpr/export/",
    { query: query }
  )

  return exportFileFromRespone(data)
}
