<template>
  <BaseModal @close="onModalClose">
    <template #title> Create Booking </template>

    <BookingForm @close="onModalClose" />
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from "./BaseModal.vue"

const emit = defineEmits<{ (event: "close"): void }>()
const onModalClose = () => emit("close")
</script>
