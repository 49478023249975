<template>
  <HeadlessListbox
    v-model="localModelValue"
    :disabled="disabled"
  >
    <BaseInputField
      #default="{
        hasError,
        inputProps: { onInput, onBlur },
      }"
      :model-value="modelValue"
      :name="name"
      :optional="optional"
      :disabled="disabled"
      :rules="rules"
      :hint="hint"
    >
      <template v-if="label">
        <HeadlessListboxLabel
          v-if="label"
          class="text-background-fg/87 mb-2 block text-xs font-semibold"
        >
          {{ label }}
        </HeadlessListboxLabel>
      </template>

      <div class="relative">
        <HeadlessListboxButton
          ref="listboxButtonRef"
          class="input relative cursor-pointer pr-10 text-left"
          :class="[
            hasError ? 'border-error' : '',
            inputClass,
          ]"
          @input="onInput"
          @blur="onBlur"
        >
          <span class="block truncate">
            {{ modelValueLabel }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <IconHeroiconsOutlineSelector
              class="h-5 w-5 text-black/40"
              aria-hidden="true"
            />
          </span>
        </HeadlessListboxButton>

        <Teleport to="#listbox-options">
          <Transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <HeadlessListboxOptions
              ref="listboxOptionsRef"
              class="bg-background ring-background-fg z-11 absolute max-h-60 overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <template
                v-for="option in options"
                :key="option.value"
              >
                <HeadlessListboxOption
                  #default="{ active, selected }"
                  as="template"
                  :value="option.value"
                >
                  <li
                    :class="[
                      active
                        ? 'bg-primary text-primary-fg'
                        : 'text-background-fg/87',
                      'relative cursor-default select-none py-2 pl-3 pr-3',
                    ]"
                  >
                    <span
                      :class="[
                        selected
                          ? 'font-semibold'
                          : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ option.label }}
                    </span>

                    <!-- <span
                      v-if="selected"
                      :class="[
                        active
                          ? 'text-primary-fg'
                          : 'text-primary',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <IconHeroiconsOutlineCheck
                        class="h-5 w-5"
                        aria-hidden="true"
                      />
                    </span> -->
                  </li>
                </HeadlessListboxOption>
              </template>
            </HeadlessListboxOptions>
          </Transition>
        </Teleport>
      </div>
    </BaseInputField>
  </HeadlessListbox>
</template>

<script
  setup
  lang="ts"
  generic="T extends string | number | null | undefined"
>
import { createPopper } from "@popperjs/core"
import { sameWidthModifier } from "@/utils/popperUtils"
import type { ComponentPublicInstance } from "vue"

// After vue 3.3 we can have props extend BaseInputFieldProps directly
// rather than copying over type definitions
const props = withDefaults(
  defineProps<{
    modelValue: T
    options: Array<{
      value: T
      label: string
    }>
    name?: string
    optional?: boolean
    label?: string
    disabled?: boolean
    rules?: Array<(value: T) => boolean | string>
    hint?: string
    inputClass?: string
  }>(),
  { disabled: false, label: "", inputClass: "" }
)

const emit = defineEmits<{
  (
    event: "update:modelValue",
    value: typeof props.modelValue
  ): void
}>()

const modelValueLabel = computed(
  () =>
    props.options.find(
      ({ value }) => value === props.modelValue
    )?.label ?? "-"
)

watch(
  () => props.options,
  (newOptions) => {
    const currentValueIsInvalid = !newOptions.some(
      (option) => option.value === props.modelValue
    )

    if (currentValueIsInvalid && newOptions.length > 0) {
      emit("update:modelValue", newOptions[0].value)
    }
  },
  { deep: true, immediate: true }
)

const localModelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value)
  },
})

const listboxOptionsRef =
  ref<ComponentPublicInstance | null>(null)
const listboxButtonRef =
  ref<ComponentPublicInstance | null>(null)

whenever(
  () =>
    listboxButtonRef.value?.$el &&
    listboxOptionsRef.value?.$el,
  () => {
    createPopper(
      listboxButtonRef.value?.$el,
      listboxOptionsRef.value?.$el,
      {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
          sameWidthModifier,
        ],
      }
    )
  }
)
</script>
