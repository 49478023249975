export const LANGUAGES = {
  english: {
    value: "en",
    label: "English",
  },
  welsh: {
    value: "cy",
    label: "Welsh",
  },
} as const

export type Language =
  (typeof LANGUAGES)[keyof typeof LANGUAGES]["value"]
