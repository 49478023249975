import { api } from "@/api"
import { queryClient } from "@/queryClient"

type LogInRequest = {
  email: string
  password: string
}

type LogInResponse = {
  accessToken: string
  refreshToken: string
}

const { setAccessToken, setRefreshToken } = useAuth()

export const logIn = async (request: LogInRequest) => {
  const { body } = await api.post<LogInResponse>(
    "/auth/user-login/",
    {
      body: request,
      authenticated: false,
    }
  )

  queryClient.invalidateQueries([SITE_QUERY_KEY])
  setAccessToken(body.accessToken)
  setRefreshToken(body.refreshToken)
}
