<template>
  <span
    class="divide-x-1 divide-background-fg/8 border-1 border-background-fg/8 inline-flex overflow-hidden rounded-md shadow-sm"
  >
    <template v-for="option in options" :key="option.value">
      <button
        type="button"
        :class="[
          ' relative inline-flex items-center px-3 py-2 text-sm font-semibold ',
          getIsOptionSelected(option.value)
            ? 'bg-primary text-primary-fg'
            : 'bg-background hover:bg-background-fg/3 text-background-fg/87',
          'disabled:opacity-70',
        ]"
        :disabled="disabled || option.isDisabled"
        @click="onClickOption(option.value)"
      >
        <span>{{ option.label }}</span>
        <div
          class="bg-background border-1 border-background-fg/8 ml-4 flex h-4 w-4 items-center justify-center rounded"
        >
          <template
            v-if="getIsOptionSelected(option.value)"
          >
            <IconHeroiconsSolidCheck class="text-primary" />
          </template>
        </div>
      </button>
    </template>
  </span>
</template>

<script setup lang="ts" generic="T extends string | number">
type Option = {
  value: T
  label: string
  isDisabled?: boolean
}

const props = defineProps<{
  modelValue: T[]
  options: Option[]
  disabled?: boolean
}>()

const emit = defineEmits<{
  (
    event: "update:modelValue",
    value: typeof props.modelValue
  ): void
}>()

const onClickOption = (optionValue: T) => {
  if (getIsOptionSelected(optionValue)) {
    emit(
      "update:modelValue",
      props.modelValue.filter(
        (value) => value !== optionValue
      )
    )
  } else {
    emit("update:modelValue", [
      ...props.modelValue,
      optionValue,
    ])
  }
}

const getIsOptionSelected = (optionValue: T) =>
  props.modelValue.includes(optionValue)
</script>
