<template>
  <BaseInputText
    v-bind="$props"
    :type="isVisible ? 'text' : 'password'"
    @update:model-value="
      (value) => emit('update:modelValue', value)
    "
  >
    <template #after>
      <button type="button" @click="toggleVisible">
        <template v-if="isVisible">
          <IconHeroiconsOutlineEye />
        </template>
        <template v-else>
          <IconHeroiconsOutlineEyeOff />
        </template>
      </button>
    </template>
  </BaseInputText>
</template>

<script setup lang="ts">
// After Vue 3.3 we can import BaseInputTextProps and set
// Props = Omit<BaseInputTextProps, "type">
defineProps<{
  name?: string
  optional?: boolean
  label?: string
  disabled?: boolean
  rules?: Array<(value: string) => boolean | string>
  hint?: string
  modelValue: string
  inputClass?: string
  pattern?: string
  placeholder?: string
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void
}>()

const isVisible = ref(false)
const toggleVisible = () => {
  isVisible.value = !isVisible.value
}
</script>
