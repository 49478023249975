import { api } from "@//api"
import {
  parseBookingApiResponse,
  type Booking,
  type BookingApiResponse,
} from "./bookings"
import { parseBookingForm } from "./createUpdateBookings"
import type { BookingForm } from "./bookingForm"
import { queryClient } from "@/queryClient"
import type { CreateUpdateBookingRequestBody } from "./createUpdateBookings"

type UpdateBookingRequest = CreateUpdateBookingRequestBody

export const updateBooking = async (
  bookingId: string,
  formValues: BookingForm
): Promise<Booking> => {
  const requestBody: UpdateBookingRequest =
    parseBookingForm(formValues)

  const { body: responseBody } =
    await api.put<BookingApiResponse>(
      `/bookings/${bookingId}`,
      {
        body: requestBody,
      }
    )

  queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])

  return parseBookingApiResponse(responseBody)
}
