<template>
  <div class="bg-background-fg/3 gap-1 rounded-lg p-4">
    <div class="mb-2 flex items-center justify-between">
      <button type="button" @click="decrementView">
        <IconHeroiconsSolidChevronLeft />
      </button>
      <p class="font-semibold">
        {{
          props.type === "month"
            ? DateFns.format(currentMonthStart, "yyyy")
            : DateFns.format(currentMonthStart, "MMMM yyyy")
        }}
      </p>
      <button type="button" @click="incrementView">
        <IconHeroiconsSolidChevronRight />
      </button>
    </div>
    <div class="grid-rows-7 mb-2 grid gap-1">
      <div class="grid grid-cols-7 gap-1">
        <template
          v-for="dayOfWeek in daysOfWeek"
          :key="dayOfWeek"
        >
          <div
            class="text-background-fg/70 flex items-center justify-center text-sm"
          >
            {{ dayOfWeek }}
          </div>
        </template>
      </div>
      <template
        v-for="(dates, startOfWeek) in daysGroupedByWeeks"
        :key="startOfWeek"
      >
        <div class="group grid grid-cols-7 gap-1">
          <template v-for="date in dates" :key="date">
            <div :class="$style.squareHack">
              <button
                type="button"
                :class="[
                  isDateHovered(date)
                    ? 'bg-primary/20'
                    : isDateActive(date)
                    ? 'bg-primary text-primary-fg'
                    : 'bg-background text-background-fg hover:bg-primary/20',
                  isDateOutOfView(date) ? 'opacity-20' : '',
                  'text-md absolute inset-0 w-full rounded font-semibold disabled:opacity-20',
                ]"
                @mouseover="updateHoveredValue(date)"
                @mouseout="updateHoveredValue(undefined)"
                @click="handleClickDate(date)"
              >
                {{ DateFns.getDate(date) }}
              </button>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as DateFns from "date-fns"
type DateInterval = {
  start?: Date
  end?: Date
}

const props = withDefaults(
  defineProps<{
    modelValue: DateInterval
  }>(),
  { modelValue: undefined, allowRange: false, type: "day" }
)
const emit = defineEmits<{
  (event: "update:modelValue", value: DateInterval): void
}>()
const getCurrentMonthStart = (date: Date | undefined) => {
  return date
    ? DateFns.startOfMonth(date)
    : DateFns.startOfMonth(new Date())
}

const currentMonthStart = ref(
  getCurrentMonthStart(
    props.modelValue?.start || new Date()
  )
)
const decrementView = () => {
  currentMonthStart.value =
    props.type === "month"
      ? DateFns.addYears(currentMonthStart.value, -1)
      : DateFns.addMonths(currentMonthStart.value, -1)
}

const incrementView = () => {
  currentMonthStart.value =
    props.type === "month"
      ? DateFns.addYears(currentMonthStart.value, 1)
      : DateFns.addMonths(currentMonthStart.value, 1)
}

const visibleDates = computed(() =>
  DateFns.eachDayOfInterval({
    start: DateFns.startOfISOWeek(currentMonthStart.value),
    end: DateFns.endOfISOWeek(
      DateFns.endOfMonth(currentMonthStart.value)
    ),
  })
)

const selectedValue = ref<Date | undefined>()

const handleClickDate = (date: Date) => {
  if (selectedValue.value) {
    emit("update:modelValue", {
      start: DateFns.min([selectedValue.value, date]),
      end: DateFns.endOfDay(
        DateFns.max([selectedValue.value, date])
      ),
    })
    selectedValue.value = undefined
  } else {
    selectedValue.value = date
  }
}

const hoveredValue = ref<Date | undefined>()
const updateHoveredValue = (date: Date | undefined) => {
  hoveredValue.value = date
}
const isDateHovered = (date: Date) =>
  selectedValue.value === date ||
  (selectedValue.value &&
    hoveredValue.value &&
    DateFns.isWithinInterval(date, {
      start: DateFns.min([
        selectedValue.value,
        hoveredValue.value,
      ]),
      end: DateFns.max([
        selectedValue.value,
        hoveredValue.value,
      ]),
    }))

const isDateActive = (date: Date) => {
  if (selectedValue.value) {
    return false
  } else if (
    props.modelValue.start === undefined &&
    props.modelValue.end === undefined
  ) {
    return true
  } else if (
    props.modelValue.end === undefined &&
    props.modelValue.start
  ) {
    return DateFns.isAfter(date, props.modelValue.start)
  } else if (
    props.modelValue.start === undefined &&
    props.modelValue.end
  ) {
    return DateFns.isBefore(date, props.modelValue.end)
  } else if (
    props.modelValue.start &&
    props.modelValue.end
  ) {
    return DateFns.isWithinInterval(date, {
      start: props.modelValue.start,
      end: props.modelValue.end,
    })
  }
}

const daysOfWeek = [
  "Mo",
  "Tu",
  "We",
  "Th",
  "Fr",
  "Sa",
  "Su",
]

const daysGroupedByWeeks = computed(() =>
  groupBy(visibleDates.value, (date) =>
    DateFns.format(
      DateFns.startOfISOWeek(date),
      "yyyy-MM-dd"
    )
  )
)

const isDateOutOfView = (date: Date) =>
  !DateFns.isSameMonth(date, currentMonthStart.value)
</script>

<style lang="scss" module>
.squareHack {
  position: relative;
  padding-top: 100%;
}
</style>
