import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

const PITCH_TYPE_QUERY_KEY = "pitch-types"

export type PitchType = {
  id: string
  name: string
  siteId: string
  colorRgb: string
}

export type PitchTypeFilter = {
  siteId: string | null
}

export type PitchTypeQuery = PitchTypeFilter

export const listPitchTypes = async (
  query: PitchTypeQuery
) => {
  const { body } = await api.get<PitchType[]>(
    "/pitch-types",
    {
      query,
    }
  )

  return body
}

export const usePitchTypes = (
  query?: MaybeRef<PitchTypeQuery>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      PITCH_TYPE_QUERY_KEY,
      computed(() => ({
        siteId: currentSiteId.value,
        ...unref(query),
      })),
    ] as const,
    queryFn: ({ queryKey: [_, query_] }) =>
      listPitchTypes(unref(query_)),
    refetchIntervalInBackground: false,
    refetchInterval: 0,
    staleTime: 60 * 60 * 1000,
  })
}
