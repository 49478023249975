<template>
  <HeadlessTransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      class="relative z-10"
      static
      @close="onModalClose"
    >
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="bg-background-fg/25 fixed inset-0 transition-opacity"
        />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-start justify-center p-4 text-center sm:p-0"
        >
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel
              class="sm:my-50 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:w-full sm:max-w-md sm:p-6"
            >
              <div class="relative mb-4">
                <h2
                  class="line-clamp-1 pr-6 text-xl font-semibold"
                >
                  <slot name="title" />
                </h2>
                <div
                  class="absolute top-0 right-0 bottom-0 flex items-center justify-center"
                >
                  <button
                    type="button"
                    tabindex="-1"
                    class="flex items-center justify-center rounded opacity-70 ring-black hover:opacity-100 focus:ring-1"
                    @click="onModalClose"
                  >
                    <IconHeroiconsSolidX />
                  </button>
                </div>
              </div>
              <slot v-bind="$attrs" />
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
defineProps<{ show: boolean }>()
const emit = defineEmits<{ (event: "close"): void }>()

const onModalClose = () => emit("close")
</script>
