<template>
  <div class="mb-8 block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <template v-for="tab in tabs" :key="tab.id">
          <button
            :class="[
              tab.id === modelValue
                ? 'border-primary '
                : 'text-background-fg/50 hover:border-background-fg/30 hover:text-background-fg/70 border-transparent',
              'cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
            ]"
            @click="setTab(tab.id)"
          >
            {{ tab.label }}
          </button>
        </template>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  tabs: Array<{
    label: string
    id: string | null
  }>
  modelValue: string | null
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: string | null): void
}>()

const setTab = (id: string | null) =>
  emit("update:modelValue", id)

watch(
  () => props.tabs,
  (newTabs) => {
    const currentValueIsInvalid = !newTabs.some(
      (tab) => tab.id === props.modelValue
    )

    if (currentValueIsInvalid) {
      const newValue =
        newTabs.length > 0 ? newTabs[0].id : null
      emit("update:modelValue", newValue)
    }
  },
  { deep: true, immediate: true }
)
</script>
