<template>
  <span
    class="rounded-full bg-opacity-20 px-3 py-1 text-xs font-medium"
    :class="[
      bgClass || 'bg-background-fg',
      textClass || 'text-background-fg',
    ]"
  >
    {{
      CAMERA_EVENT_STATUS_MAP[props.status]?.label ||
      "unknown"
    }}
  </span>
</template>

<script setup lang="ts">
import type { CameraEventStatus } from "@/services/cameraEvents/listCameraEvents"
import { CAMERA_EVENT_STATUS_MAP } from "@/services/cameraEvents/listCameraEvents"

const props = defineProps<{
  status: CameraEventStatus
}>()

const bgClass = computed(() =>
  props.status
    ? `bg-${CAMERA_EVENT_STATUS_MAP[props.status].color}`
    : ""
)

const textClass = computed(() =>
  props.status
    ? `text-${CAMERA_EVENT_STATUS_MAP[props.status].color}`
    : ""
)
</script>
