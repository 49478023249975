import type { MaybeRef } from "@vueuse/core"

interface UsePaginationOptions {
  count: MaybeRef<number>
  limit: MaybeRef<number>
}

export const usePagination = ({
  count,
  limit = 10,
}: UsePaginationOptions) => {
  const defaultPagination = computed(() => ({
    limit: unref(limit),
    offset: 0,
  }))

  const offset = ref(0)
  const pagination = computed(() => ({
    offset: unref(offset),
    limit: unref(limit),
  }))

  const resetPagination = () => {
    offset.value = 0
  }

  const page = computed({
    get: () =>
      Math.floor(
        pagination.value.offset / pagination.value.limit
      ) + 1,
    set: (value) => {
      offset.value = pagination.value.limit * (value - 1)
    },
  })

  const pageCount = computed(() =>
    Math.ceil(unref(count) / pagination.value.limit)
  )

  return {
    page,
    pageCount: readonly(pageCount),
    pagination: readonly(pagination),
    resetPagination,
  }
}
