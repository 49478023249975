import zlFetch from "zl-fetch"

const methods = [
  "get",
  "post",
  "put",
  "patch",
  "delete",
] as const

interface ApiOptions {
  headers?: HeadersInit
  body?: object | string | undefined
  debug?: boolean
  returnError?: boolean
  customResponseParser?: boolean
  query?: object
  authenticated?: boolean
}

export type ApiResponse<T> = {
  headers: Headers
  body: T
  status: number
  statusText: string
  response: Response
}

type RequestDataSource =
  | "path"
  | "query"
  | "header"
  | "cookie"
  | "body"
  | "form"
  | "file"

type RequestSourceErrorMap = {
  [location: string]: string
}

type NinjaErrorBody = {
  detail: string
  code?: string
  requestErrors?: Partial<
    Record<RequestDataSource, RequestSourceErrorMap>
  >
  otherErrors?: Array<string>
}

export class ApiError extends Error {
  status: number
  detail?: string
  code?: string
  requestErrors?: Partial<
    Record<RequestDataSource, RequestSourceErrorMap>
  >
  otherErrors?: Array<string>

  constructor(response: ApiResponse<unknown>) {
    super()

    this.status = response.status

    if (isNinjaErrorBody(response.body)) {
      this.detail = response.body.detail
      this.code = response.body.code
      this.requestErrors = response.body.requestErrors
      this.otherErrors = response.body.otherErrors
    }
  }
}

export class FailedToFetchError extends Error {
  constructor() {
    super("Failed to fetch")
  }
}

type FailedToFetchResponse = {
  error: Error
}

type ApiMethod = <T>(
  url: string,
  options?: ApiOptions
) => Promise<ApiResponse<T>>

type Api = Record<typeof methods[number], ApiMethod>

const buildUrl = (path: string) => `${__API_ROOT__}${path}`
export const api = {} as Api

for (const method of methods) {
  api[method] = async (
    path: string,
    {
      query,
      authenticated = true,
      ...options
    }: ApiOptions = {}
  ) => {
    const { accessToken, logOut } = useAuth()

    const url = query
      ? buildUrl(`${path}${buildQueryString(query)}`)
      : buildUrl(path)

    const doRequest = () =>
      zlFetch(url, {
        ...(authenticated && { auth: accessToken.value }),
        ...options,
        method: method.toUpperCase(),
      })

    try {
      return await doRequest()
    } catch (error) {
      if (isFailedToFetchResponse(error)) {
        throw new FailedToFetchError()
      }

      const typedError = error as ApiResponse<unknown>

      if (typedError.status === 401) {
        logOut()
      }

      throw new ApiError(typedError)
    }
  }
}

const isNinjaErrorBody = (
  body: unknown
): body is NinjaErrorBody => {
  return (
    typeof body === "object" &&
    body != null &&
    "detail" in body
  )
}

const isFailedToFetchResponse = (
  error: unknown
): error is FailedToFetchResponse => {
  // Wierd type castings required by vue-tsc but not regular typescript. To investigate later.
  return (
    typeof error === "object" &&
    error != null &&
    "error" in error &&
    (error as { error: unknown }).error instanceof Error &&
    (error as { error: Error }).error.message ===
      "Failed to fetch"
  )
}
