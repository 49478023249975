<template>
  <div
    ref="scrollContainerRef"
    class="max-h-70vh h-full overflow-y-auto px-8"
  >
    <ol class="min-h-full">
      <template v-for="page in data?.pages">
        <template
          v-for="alert in page.items"
          :key="alert.id"
        >
          <li :class="[alert.isSeen ? 'opacity-30' : '']">
            <button @click="handleClickAlert(alert)">
              <div class="py-3 px-2">
                <div class="mb-2 flex justify-between">
                  <p class="text-primary mr-4 text-sm">
                    {{ alert.title }}
                  </p>
                  <p class="text-xs">
                    {{
                      formatShortDateTime(alert.createdAt)
                    }}
                  </p>
                </div>
                <div
                  class="flex flex-nowrap justify-between"
                >
                  <p
                    class="line-clamp-5 text-background-fg/70 mr-4 text-sm"
                  >
                    {{ alert.content }}
                  </p>
                </div>
              </div>
            </button>
          </li>
        </template>
      </template>
      <template v-if="isFetchingNextPage">
        <div
          class="bg-background-fg/30 my-2 h-2 animate-pulse rounded"
        >
        </div>
      </template>
    </ol>
  </div>
</template>

<script setup lang="ts">
import type { Alert } from "@/services/alerts/listAlerts"

const props = defineProps<{ bookingId: string }>()

const {
  data,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
} = useAlerts({ bookingId: props.bookingId })

const formatShortDateTime = (value: string) => {
  return DateFns.format(
    DateFns.parseISO(value),
    "ccc do MMM, HH:mm"
  )
}

const scrollContainerRef = ref<HTMLDivElement | null>(null)

const { arrivedState } = useScroll(
  computed(() => scrollContainerRef.value)
)
whenever(
  () => arrivedState.bottom && unref(hasNextPage),
  () => fetchNextPage()
)

const handleClickAlert = (alert: Alert) => {
  return markAlertAsSeen(alert)
}
</script>
