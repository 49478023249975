export const BOOKING_TYPES = {
  default: {
    value: "default",
    label: "Standard",
  },
  seasonal: {
    value: "seasonal",
    label: "Seasonal",
  },
  storage: {
    value: "storage",
    label: "Storage",
  },
} as const

export type BookingType =
  (typeof BOOKING_TYPES)[keyof typeof BOOKING_TYPES]["value"]
