<template>
  <div class="relative mb-4">
    <h2 class="line-clamp-1 pr-6 text-xl font-semibold">
      Add Allowlist Entry
    </h2>
    <div
      class="absolute top-0 right-0 bottom-0 flex items-center justify-center"
    >
      <button
        type="button"
        tabindex="-1"
        class="flex items-center justify-center rounded opacity-70 ring-black hover:opacity-100 focus:ring-1"
        @click="onModalClose"
      >
        <IconHeroiconsSolidX />
      </button>
    </div>
  </div>

  <AllowlistForm @cancel="onModalClose"></AllowlistForm>
</template>

<script setup lang="ts">
const emit = defineEmits<{ (event: "close"): void }>()
const onModalClose = () => emit("close")
</script>
