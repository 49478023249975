<template>
  <div>
    <p class="font-semibold leading-normal">
      {{ customerFullName }}
    </p>

    <div>
      <span
        :class="[
          'bg-background-fg/8 mx-auto inline-flex items-center rounded-full py-0.5 pl-2 text-xs',
          customer.phone ? 'pr-0.5' : 'pr-2',
        ]"
      >
        {{ customer.membershipNumber }}
        <div
          v-if="customer.phone"
          class="ml-2 flex h-full items-center justify-center rounded-full bg-white p-0.5"
        >
          <PhoneIcon class="h-4/5 w-4/5" />
        </div>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Customer } from "@/services/bookings/customers"
import PhoneIcon from "~icons/fa6-solid/phone-flip"

const props = defineProps<{ customer: Customer }>()

const customerFullName = computed<string>(
  () =>
    `${props.customer.title} ${props.customer.firstName} ${props.customer.lastName}`
)
</script>
