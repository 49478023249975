<template>
  <div class="relative mb-4">
    <h2 class="line-clamp-1 pr-6 text-xl font-semibold">
    </h2>
    <div
      class="absolute top-0 right-0 bottom-0 flex items-center justify-center"
    >
      <button
        type="button"
        tabindex="-1"
        class="flex items-center justify-center rounded opacity-70 ring-black hover:opacity-100 focus:ring-1"
        @click="onModalClose"
      >
        <IconHeroiconsSolidX />
      </button>
    </div>
  </div>
  <div class="mb-8 block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <template v-for="tab in tabs" :key="tab">
          <a
            :class="[
              tab === activeTab
                ? 'border-primary '
                : 'text-background-fg/50 hover:border-background-fg/30 hover:text-background-fg/70 border-transparent',
              'cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
            ]"
            :aria-current="
              tab === 'activeTab' ? 'page' : undefined
            "
            @click="setTab(tab)"
          >
            {{ tab }}
          </a>
        </template>
      </nav>
    </div>
  </div>

  <template
    v-if="activeTab === 'Details' && allowlistEntry"
  >
    <AllowlistForm
      :initial-data="allowlistEntry"
      @cancel="onModalClose"
    />
  </template>

  <template v-else-if="activeTab === 'ANPR Log'">
    <CameraEventsTable :filters="cameraEventsFilters" />
  </template>

  <template v-if="isAllowlistEntryLoading">
    <BaseContainerLoading tabindex="-1" />
  </template>
</template>

<script setup lang="ts">
const props = defineProps<{
  allowlistEntryId: string
}>()

const emit = defineEmits<{
  (event: "close"): void
}>()

const onModalClose = () => emit("close")

const activeTab = ref("Details")

const tabs = ["Details", "ANPR Log"]

const setTab = (tab: string) => {
  activeTab.value = tab
}

const {
  data: allowlistEntry,
  isFetching: isAllowlistEntryLoading,
} = useAllowlistEntry(toRef(props, "allowlistEntryId"))

const cameraEventsFilters = reactive({
  vehicleRegistration: computed(
    () => allowlistEntry.value?.vehicleRegistration
  ),
})
</script>
