<template>
  <div
    class="ring-background-fg/5 overflow-hidden rounded-lg shadow ring-1"
  >
    <div class="relative overflow-x-auto pb-2">
      <template v-if="isLoading">
        <div class="absolute inset-x-0 top-0">
          <BaseLoadingBar />
        </div>
      </template>
      <table
        class="divide-background-fg/5 min-w-full divide-y-2 overflow-x-auto text-xs"
      >
        <thead class="bg-background-fg/3">
          <tr>
            <template
              v-for="column in columns"
              :key="column.name"
            >
              <th
                scope="col"
                class="whitespace-nowrap px-4 py-6 font-semibold text-black/90"
                :class="[
                  column.align === 'right'
                    ? 'text-right'
                    : column.align === 'center'
                    ? 'text-center'
                    : 'text-left',
                  column.isSortable ? 'cursor-pointer' : '',
                ]"
                @click="onClickHeader(column)"
              >
                <div
                  class="flex space-x-2"
                  :class="
                    column.align === 'right'
                      ? 'justify-end'
                      : column.align === 'center'
                      ? 'justify-center'
                      : 'justify-start'
                  "
                >
                  <span>
                    <slot
                      :name="`header:${column.name}`"
                      v-bind="{ column }"
                    >
                      {{ column.label }}
                    </slot>
                  </span>
                  <template v-if="column.isSortable">
                    <template v-if="sortBy === column.name">
                      <IconHeroiconsSolidChevronUp
                        class="h-4 w-4"
                      />
                    </template>
                    <template
                      v-else-if="
                        sortBy === `-${column.name}`
                      "
                    >
                      <IconHeroiconsSolidChevronDown
                        class="h-4 w-4"
                      />
                    </template>
                    <template v-else>
                      <span class="h-4 w-4" />
                    </template>
                  </template>
                </div>
              </th>
            </template>
          </tr>
        </thead>

        <tbody
          class="divide-background-fg/5 divide-y-2 bg-white"
        >
          <template v-if="rows && rows.length > 0">
            <template
              v-for="row in rows"
              :key="row[keyField]"
            >
              <tr
                :class="rowClass"
                @click="emit('clickRow', row)"
              >
                <template
                  v-for="column in columns"
                  :key="column.name"
                >
                  <td
                    :class="[
                      column.autoWidth
                        ? 'w-0 whitespace-nowrap'
                        : '',
                      'whitespace-nowrap px-3 py-2 text-black/70',
                      column.align === 'right'
                        ? 'text-right'
                        : column.align === 'center'
                        ? 'text-center'
                        : 'text-left',
                    ]"
                  >
                    <slot
                      :name="`body-cell:${column.name}`"
                      v-bind="{
                        value: column.getValue(row),
                      }"
                    >
                      {{ column.getValue(row) }}
                    </slot>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template v-else-if="rows">
            <tr>
              <td :colspan="columns.length">
                <span
                  class="text-background-fg/70 block py-2 text-center text-base font-medium"
                >
                  No records found
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts" generic="TRow">
import type { ColumnDefinition } from "@/types/table"

type Props<T> = {
  columns: Array<ColumnDefinition<T>>
  rows: Array<T>
  rowClass?: string
  isLoading: boolean
  sortBy?: string | null
  keyField: keyof T
}

const props = defineProps<Props<TRow>>()

const emit = defineEmits<{
  (event: "clickRow", params: TRow): void
  (event: "update:sortBy", value: string | null): void
}>()

defineSlots<
  {
    [K in `header:${string}`]: (props: {
      column: ColumnDefinition<TRow>
    }) => any
  } & {
    [K in `body-cell:${string}`]: (props: {
      value: any
    }) => any
  }
>()

const onClickHeader = (column: ColumnDefinition<TRow>) => {
  if (!column.isSortable) {
    return
  } else if (props.sortBy === `-${column.name}`) {
    emit("update:sortBy", column.name)
  } else if (props.sortBy === column.name) {
    emit("update:sortBy", null)
  } else {
    emit("update:sortBy", `-${column.name}`)
  }
}
</script>
