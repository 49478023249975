<template>
  <span
    class="rounded-full bg-opacity-20 px-3 py-1 text-xs font-medium"
    :class="[bgClass, textClass]"
  >
    {{ BOOKING_STATUS_MAP[props.status]?.label }}
  </span>
</template>

<script setup lang="ts">
import type { BookingStatus } from "@/services/bookings/bookings"
import { BOOKING_STATUS_MAP } from "@/services/bookings/bookings"
const props = defineProps<{
  status: BookingStatus
}>()

const bgClass = computed(
  () =>
    `bg-${
      BOOKING_STATUS_MAP[props.status]?.color || "black"
    }`
)

const textClass = computed(
  () =>
    `text-${
      BOOKING_STATUS_MAP[props.status]?.color || "black"
    }`
)
</script>
