<template>
  <div class="relative">
    <div
      class="border-background-fg/20 relative w-full overflow-hidden rounded-md border text-sm shadow-sm"
      :class="[
        data?.pages?.[0]?.items.length
          ? 'rounded-b-none border-b-0'
          : '',
      ]"
    >
      <input
        v-model="search"
        class="w-full py-3 pr-4 pl-9"
        placeholder="Search"
      />
      <div
        class="absolute inset-y-0 left-3 flex items-center"
      >
        <IconHeroiconsSolidMagnifyingGlass
          class="background-fg/20"
        />
      </div>
      <div
        class="absolute inset-y-0 right-2 flex items-center"
      >
        <button
          v-if="search === ''"
          class="border-background-fg/20 hover:bg-amber flex items-center rounded-full border px-3 py-1 text-sm font-semibold transition hover:bg-opacity-20"
          @click.prevent="$emit('create')"
        >
          <IconHeroiconsSolidPlus class="mr-2" />
          New customer
        </button>
      </div>
    </div>
    <div
      v-if="data?.pages?.[0]?.items.length"
      ref="scrollContainerRef"
      class="z-1 border-background-fg/20 absolute top-full left-0 max-h-64 w-full overflow-y-auto rounded-b-md border bg-white"
    >
      <ul class="min-h-full">
        <template v-for="page in data?.pages">
          <template
            v-for="customer in page.items"
            :key="customer.id"
          >
            <li
              class="hover:bg-amber flex w-full justify-around py-1.5 px-4 hover:bg-opacity-20"
              @click.stop="$emit('update', customer)"
            >
              <span
                >{{ customer.firstName }}
                {{ customer.lastName }}</span
              >
              <span class="text-background-fg/50 ml-auto">{{
                customer.membershipNumber
              }}</span>
            </li>
          </template>
        </template>
        <template v-if="isFetchingNextPage">
          <div
            class="bg-background-fg/30 my-2 h-2 animate-pulse rounded"
          >
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Customer } from "@/services/bookings/customers"

defineEmits<{
  (event: "update", customer: Customer): void
  (event: "create"): void
}>()

const search = ref("")
const searchDebounced = debouncedRef<string>(search, 500)

const {
  data,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
} = useInfiniteCustomers(
  computed(() => ({ search: searchDebounced.value }))
)

const scrollContainerRef = ref<HTMLDivElement | null>(null)

const { arrivedState } = useScroll(
  computed(() => scrollContainerRef.value)
)
whenever(
  () => arrivedState.bottom && unref(hasNextPage),
  () => fetchNextPage()
)
</script>
