<template>
  <div
    class="min-w-64 text-background-fg/70 relative rounded-md shadow"
  >
    <HeadlessListbox
      :model-value="intervalType"
      @update:model-value="onUpdateIntervalType"
    >
      <HeadlessListboxButton
        ref="listboxButtonRef"
        class="focus:border-primary focus:ring-primary relative flex w-full cursor-pointer items-center justify-center rounded-md border border-black/20 bg-white px-10 py-2 text-center font-semibold shadow-sm focus:outline-none sm:text-sm"
      >
        <button
          class="absolute left-0 top-0 bottom-0 flex items-center justify-center p-1"
          type="button"
          :disabled="intervalType === 'custom'"
          @click.prevent="onIntervalPreviousClick"
        >
          <IconHeroiconsSolidChevronLeft class="h-6 w-6" />
        </button>
        <span class="block truncate">
          {{ formattedModelValue }}
        </span>
        <button
          class="absolute right-0 top-0 bottom-0 flex items-center justify-center p-1"
          type="button"
          :disabled="intervalType === 'custom'"
          @click.prevent="onIntervalNextClick"
        >
          <IconHeroiconsSolidChevronRight class="h-6 w-6" />
        </button>
      </HeadlessListboxButton>
      <Teleport to="#listbox-options">
        <Transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <HeadlessListboxOptions
            ref="listboxOptionsRef"
            as="template"
            class="bg-background ring-background-fg absolute z-10 overflow-auto rounded-md text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div class="flex">
              <ul>
                <template
                  v-for="option in intervalTypeOptions"
                  :key="option.value"
                >
                  <HeadlessListboxOption
                    #default="{ active, selected }"
                    as="template"
                    :value="option.value"
                  >
                    <li
                      :class="[
                        active
                          ? 'bg-primary text-primary-fg'
                          : 'text-background-fg/87',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9',
                      ]"
                    >
                      <span
                        :class="[
                          selected
                            ? 'font-semibold'
                            : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        {{ option.label }}
                      </span>
                    </li>
                  </HeadlessListboxOption>
                </template>
              </ul>
              <div class="p-1">
                <DateSelect
                  v-model="datePickerModel"
                  class="w-64"
                />
              </div>
            </div>
          </HeadlessListboxOptions>
        </Transition>
      </Teleport>
    </HeadlessListbox>
  </div>
</template>

<script setup lang="ts">
import { createPopper } from "@popperjs/core"
import type { ComponentPublicInstance } from "vue"

type FiniteDateInterval = {
  start: Date
  end: Date
}

type DateInterval = Partial<FiniteDateInterval>

type IntervalType =
  | "day"
  | "week"
  | "month"
  | "year"
  | "allTime"
  | "custom"

const props = defineProps<{
  modelValue: DateInterval
  disableUnbounded?: boolean
}>()

const emit = defineEmits<{
  (event: "update:modelValue", value: DateInterval): void
}>()

const datePickerModel = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const intervalIsFinite = (
  dateInterval: DateInterval
): dateInterval is FiniteDateInterval =>
  Boolean(dateInterval.start && dateInterval.end)

const intervalIsDay = (dateInterval: DateInterval) =>
  intervalIsFinite(dateInterval) &&
  DateFns.isEqual(
    DateFns.startOfDay(dateInterval.start),
    dateInterval.start
  ) &&
  DateFns.isEqual(
    DateFns.endOfDay(dateInterval.start),
    dateInterval.end
  )

const intervalIsWeek = (dateInterval: DateInterval) =>
  intervalIsFinite(dateInterval) &&
  DateFns.isEqual(
    DateFns.startOfISOWeek(dateInterval.start),
    dateInterval.start
  ) &&
  DateFns.isEqual(
    DateFns.endOfISOWeek(dateInterval.start),
    dateInterval.end
  )

const intervalIsMonth = (dateInterval: DateInterval) =>
  intervalIsFinite(dateInterval) &&
  DateFns.isEqual(
    DateFns.startOfMonth(dateInterval.start),
    dateInterval.start
  ) &&
  DateFns.isEqual(
    DateFns.endOfMonth(dateInterval.start),
    dateInterval.end
  )

const intervalIsYear = (dateInterval: DateInterval) =>
  intervalIsFinite(dateInterval) &&
  DateFns.isEqual(
    DateFns.startOfYear(dateInterval.start),
    dateInterval.start
  ) &&
  DateFns.isEqual(
    DateFns.endOfYear(dateInterval.end),
    dateInterval.end
  )

const intervalIsAllTime = (dateInterval: DateInterval) =>
  dateInterval.start === undefined &&
  dateInterval.end === undefined

const intervalType = computed<IntervalType>(() => {
  if (intervalIsDay(props.modelValue)) {
    return "day"
  } else if (intervalIsWeek(props.modelValue)) {
    return "week"
  } else if (intervalIsMonth(props.modelValue)) {
    return "month"
  } else if (intervalIsAllTime(props.modelValue)) {
    return "allTime"
  } else if (intervalIsYear(props.modelValue)) {
    return "year"
  } else {
    return "custom"
  }
})

const intervalTypeOptions = computed<
  Array<{
    value: IntervalType
    label: string
  }>
>(() => [
  {
    value: "day",
    label: "Today",
  },
  {
    value: "week",
    label: "This Week",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
  ...(props.disableUnbounded
    ? []
    : ([
        {
          value: "allTime",
          label: "All Time",
        },
      ] as const)),
])

const onIntervalPreviousClick = () => {
  let newModelValue: DateInterval

  if (!props.modelValue.start || !props.modelValue.end) {
    return
  }
  switch (intervalType.value) {
    case "day": {
      const start = DateFns.startOfDay(
        DateFns.addDays(props.modelValue.start, -1)
      )
      newModelValue = {
        start,
        end: DateFns.endOfDay(start),
      }
      break
    }
    case "week": {
      const start = DateFns.addWeeks(
        DateFns.startOfISOWeek(props.modelValue.start),
        -1
      )
      newModelValue = {
        start,
        end: DateFns.endOfISOWeek(start),
      }
      break
    }
    case "month": {
      const start = DateFns.addMonths(
        DateFns.startOfMonth(props.modelValue.start),
        -1
      )

      newModelValue = {
        start,
        end: DateFns.endOfMonth(start),
      }
      break
    }
    case "year": {
      const start = DateFns.addYears(
        DateFns.startOfYear(props.modelValue.start),
        -1
      )

      newModelValue = {
        start,
        end: DateFns.endOfYear(start),
      }
      break
    }
    default:
      newModelValue = props.modelValue
  }
  emit("update:modelValue", newModelValue)
}

const onIntervalNextClick = () => {
  let newModelValue: DateInterval
  if (!props.modelValue.start || !props.modelValue.end) {
    return
  }
  switch (intervalType.value) {
    case "day": {
      const start = DateFns.startOfDay(
        DateFns.addDays(props.modelValue.start, 1)
      )
      newModelValue = {
        start,
        end: DateFns.endOfDay(start),
      }
      break
    }
    case "week": {
      const start = DateFns.addWeeks(
        DateFns.startOfISOWeek(props.modelValue.start),
        1
      )
      newModelValue = {
        start: start,
        end: DateFns.endOfISOWeek(start),
      }
      break
    }
    case "month": {
      const start = DateFns.addMonths(
        DateFns.startOfMonth(props.modelValue.start),
        1
      )
      newModelValue = {
        start: start,
        end: DateFns.endOfMonth(start),
      }
      break
    }
    case "year": {
      const start = DateFns.addYears(
        DateFns.startOfYear(props.modelValue.start),
        1
      )
      newModelValue = {
        start: start,
        end: DateFns.endOfYear(start),
      }
      break
    }
    default:
      newModelValue = props.modelValue
  }

  emit("update:modelValue", newModelValue)
}

const onUpdateIntervalType = (newType: IntervalType) => {
  const today = new Date()

  let newModelValue: DateInterval
  switch (newType) {
    case "day": {
      newModelValue = {
        start: DateFns.startOfDay(today),
        end: DateFns.endOfDay(today),
      }
      break
    }
    case "week": {
      newModelValue = {
        start: DateFns.startOfISOWeek(today),
        end: DateFns.endOfISOWeek(today),
      }
      break
    }
    case "month": {
      newModelValue = {
        start: DateFns.startOfMonth(today),
        end: DateFns.endOfMonth(today),
      }
      break
    }
    case "year": {
      newModelValue = {
        start: DateFns.startOfYear(today),
        end: DateFns.endOfYear(today),
      }
      break
    }
    case "allTime": {
      newModelValue = {
        start: undefined,
        end: undefined,
      }
      break
    }

    default:
      newModelValue = props.modelValue
  }

  emit("update:modelValue", newModelValue)
}

const isToday = (interval: DateInterval) =>
  intervalIsFinite(interval) &&
  intervalIsDay(interval) &&
  DateFns.isToday(interval.start)

const isThisWeek = (interval: DateInterval) =>
  intervalIsFinite(interval) &&
  intervalIsWeek(interval) &&
  DateFns.isWithinInterval(new Date(), interval)

const formattedModelValue = computed(() => {
  if (intervalIsFinite(props.modelValue)) {
    switch (intervalType.value) {
      case "day": {
        return isToday(props.modelValue)
          ? "Today"
          : DateFns.format(
              props.modelValue.start,
              "ccc do MMM"
            )
      }
      case "week": {
        return isThisWeek(props.modelValue)
          ? "This Week"
          : `${DateFns.format(
              props.modelValue.start,
              "do MMM"
            )} - ${DateFns.format(
              props.modelValue.end,
              "do MMM"
            )}`
      }
      case "month": {
        return `${DateFns.format(
          props.modelValue.start,
          "MMM yy"
        )}`
      }
      case "year": {
        return `${DateFns.format(
          props.modelValue.start,
          "yyyy"
        )}`
      }

      default:
        return `${DateFns.format(
          props.modelValue.start,
          "do MMM"
        )} - ${DateFns.format(
          props.modelValue.end,
          "do MMM"
        )}`
    }
  } else if (intervalIsAllTime(props.modelValue)) {
    return "All Time"
  } else {
    return ""
  }
})

const listboxOptionsRef =
  ref<ComponentPublicInstance | null>(null)
const listboxButtonRef =
  ref<ComponentPublicInstance | null>(null)

whenever(
  () =>
    listboxButtonRef.value?.$el &&
    listboxOptionsRef.value?.$el,
  () => {
    createPopper(
      listboxButtonRef.value?.$el,
      listboxOptionsRef.value?.$el,
      {
        placement: "bottom-end",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ],
      }
    )
  }
)
// onMounted(() => {
//   if (!props.modelValue.start || !props.modelValue.end) {
//     modelValue.value = {
//       start: DateFns.startOfDay(new Date()),
//       end: DateFns.endOfDay(new Date()),
//     }
//   }
// })
</script>
