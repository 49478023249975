<template>
  <BaseInputField
    #default="{ inputProps, hasError }"
    :model-value="modelValue"
    :name="name"
    :optional="optional"
    :label="label"
    :disabled="disabled"
    :rules="rules"
    :hint="hint"
  >
    <div class="relative">
      <input
        v-bind="{ ...inputProps }"
        v-model="maskedValue"
        type="text"
        class="input"
        :class="[
          inputClass,
          hasError ? 'border-error' : '',
        ]"
        tabindex="0"
        inputmode="numeric"
        @keydown="onKeydown"
        @keydown.backspace="onBackspace"
      />

      <slot />
    </div>
  </BaseInputField>
</template>

<script setup lang="ts">
// After vue 3.3 we can have props extend BaseInputFieldProps directly
// rather than copying over type definitions
const props = withDefaults(
  defineProps<{
    modelValue: number
    inputClass?: string
    name?: string
    optional?: boolean
    label?: string
    disabled?: boolean
    rules?: Array<(value: number) => boolean | string>
    hint?: string
  }>(),
  {
    inputClass: "",
  }
)

const emit = defineEmits<{
  (event: "update:modelValue", value: number): void
}>()

const addMask = formatToCurrencyString
const removeMask = (value: string) =>
  Number(value.replace(/\D+/g, ""))

const unformatted = useVModel(props, "modelValue", emit)

const maskedValue = computed<string>({
  get: () => addMask(unformatted.value),
  set: (value) => {
    unformatted.value = removeMask(value)
  },
})

const onKeydown = (event: KeyboardEvent) => {
  const key = event.key
  if (/^[^\d]$/u.test(key)) {
    event.preventDefault()
  }
}

const onBackspace = (event: KeyboardEvent) => {
  if (unformatted.value === 0) {
    event?.preventDefault()
  }
}
</script>
