import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { BaseReportFilters } from "./reports"
import type { Ref } from "vue"

export const REPORT_OVERVIEW_QUERY_KEY = "overview-reports"

export type OverviewReportRow = {
  siteName: string
  totalArrivals: number
  totalAnprArrivals: number
  totalFastCheckIn: number
  totalManualCheckIn: number
  totalAutoPay: number
  totalArrivalsWithoutPaying: number
  totalArrivalsWithoutPayingFastCheckIn: number
  totalPaidInAdvance: number
  totalOutsideEntryTimeAttempts: number
  totalCreatedManual: number
  percentageCreatedManual: string
  totalCreatedTravellink: number
  percentageCreatedTravellink: string
}

export type OverviewReport = OverviewReportRow[]

export const retrieveOverviewReport = async (
  query: BaseReportFilters
) => {
  const { body } = await api.get<OverviewReport>(
    "/reports/overview/",
    { query: query }
  )

  return body
}

export const useOverviewReport = (
  filters: BaseReportFilters | Ref<BaseReportFilters>
) => {
  return useQuery({
    queryKey: [REPORT_OVERVIEW_QUERY_KEY, filters] as const,
    queryFn: ({ queryKey: [_, query] }) =>
      retrieveOverviewReport(unref(query)),
    staleTime: 60 * 60 * 1000,
  })
}

export const exportOverviewReport = async (
  query: BaseReportFilters
) => {
  const data = await api.get<Blob>(
    "/reports/overview/export/",
    { query: query }
  )

  return exportFileFromRespone(data)
}
