<template>
  <div class="flex justify-between py-3 px-2">
    <span class="text-sm">
      {{ barrier.name }}
    </span>
    <BaseInputToggle
      :model-value="barrierOn"
      @update:model-value="handleUpdateModelValue"
    >
      <template #label>
        <HeadlessSwitchLabel
          as="span"
          class="text-primary order-first mr-3 text-sm font-medium"
        >
          OPEN
        </HeadlessSwitchLabel>
      </template>
    </BaseInputToggle>
  </div>
</template>

<script setup lang="ts">
import type { Barrier } from "@/services/barriers/listBarriers"
const props = defineProps<{ barrier: Barrier }>()

const barrierOn = refAutoReset(false, 5 * 1000)

const handleUpdateModelValue = async (value: boolean) => {
  if (!value) {
    barrierOn.value = false
    return
  }

  barrierOn.value = true
  try {
    await pulseBarrier(props.barrier.id)
  } catch {
    barrierOn.value = false
  }
}
</script>
