<template>
  <HeadlessPopover as="div" class="relative ml-6">
    <HeadlessPopoverButton
      ref="popperTargetRef"
      href="#"
      class="text-background-fg/50 relative ml-5 flex flex-shrink-0 items-center justify-center rounded-full bg-white p-1 hover:text-opacity-80 focus:outline-none"
    >
      <span class="sr-only">View notifications</span>
      <IconHeroiconsSolidBell
        class="h-6 w-6"
        aria-hidden="true"
      />
      <template v-if="unseenCount">
        <div
          class="bg-primary text-primary-fg absolute -top-3 -right-3 flex h-6 w-6 items-center justify-center rounded-full text-xs"
        >
          {{ unseenCountString }}
        </div>
      </template>
    </HeadlessPopoverButton>
    <template v-if="data">
      <Transition
        enter-active-class="ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-75"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeadlessPopoverPanel
          ref="scrollContainerRef"
          class="ring-background-fg bg-background max-w-100 max-h-128 absolute left-0 w-screen origin-top-right divide-y-2 overflow-auto rounded-md p-4 py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none"
        >
          <ol>
            <template v-for="page in data.pages">
              <template
                v-for="alert in page.items"
                :key="alert.id"
              >
                <li
                  :class="[
                    alert.isSeen ? 'opacity-30' : '',
                  ]"
                >
                  <Component
                    :is="
                      alert.href ? RouterLink : 'template'
                    "
                    :to="alert.href"
                  >
                    <div class="py-3 px-2">
                      <div
                        class="mb-2 flex justify-between"
                      >
                        <p
                          class="text-primary mr-4 text-sm"
                        >
                          {{ alert.title }}
                        </p>
                        <p class="text-xs">
                          {{
                            formatShortDateTime(
                              alert.createdAt
                            )
                          }}
                        </p>
                      </div>
                      <div
                        class="flex flex-nowrap justify-between"
                      >
                        <p
                          class="line-clamp-5 text-background-fg/70 mr-4 text-sm"
                        >
                          {{ alert.content }}
                        </p>
                        <button
                          v-if="!alert.isSeen"
                          type="button"
                          @click.prevent="
                            handleSetAlertAsViewed(alert)
                          "
                        >
                          <IconHeroiconsSolidX />
                        </button>
                      </div>
                    </div>
                  </Component>
                </li>
              </template>
            </template>
            <template v-if="isFetchingNextPage">
              <div
                class="bg-background-fg/30 my-2 h-2 animate-pulse rounded"
              >
              </div>
            </template>
          </ol>
        </HeadlessPopoverPanel>
      </Transition>
    </template>
  </HeadlessPopover>
</template>

<script setup lang="ts">
import { RouterLink } from "vue-router"
import { createPopper } from "@popperjs/core"
import type { Alert } from "@/services/alerts/listAlerts"
import type { ComponentPublicInstance } from "vue"

const {
  data,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
} = useAlerts()

const unseenCount = computed(
  () => data.value?.pages?.[0]?.unseenCount || 0
)
const formatShortDateTime = (value: string) => {
  return DateFns.format(
    DateFns.parseISO(value),
    "ccc do MMM, HH:mm"
  )
}

const scrollContainerRef =
  ref<ComponentPublicInstance | null>(null)

const { arrivedState } = useScroll(
  computed(() => scrollContainerRef.value?.$el)
)
whenever(
  () => arrivedState.bottom && unref(hasNextPage),
  () => fetchNextPage()
)

const unseenCountString = computed(() => {
  const count = unseenCount.value
  return count > 10 ? "10+" : count
})

const handleSetAlertAsViewed = (alert: Alert) => {
  return markAlertAsSeen(alert)
}
const popperTargetRef = ref<ComponentPublicInstance | null>(
  null
)
const popperRef = scrollContainerRef

whenever(
  () => popperTargetRef.value?.$el && popperRef.value?.$el,
  () => {
    createPopper(
      popperTargetRef.value?.$el,
      popperRef.value?.$el,
      {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ],
      }
    )
  }
)
</script>
