import { createVNode, render } from "vue"
import type { Component, App, VNode, VNodeProps } from "vue"

export const mountComponent = <
  PropsT extends Record<string, unknown> & VNodeProps
>(
  component: Component,
  {
    props,
    children,
    app,
  }: {
    props?: PropsT
    children?: unknown
    app?: App
    timeout?: number
  } = {}
): {
  vNode: VNode | undefined
  el: HTMLElement | undefined
  destroy: () => void
} => {
  const vNode: VNode | undefined = createVNode(
    component,
    props,
    children
  )
  if (app && app._context) vNode.appContext = app._context

  const element_ = document.createElement("div")
  document.body.appendChild(element_)
  render(vNode, element_)

  const destroy = () => {
    if (element_) render(null, element_)
    if (typeof element_.remove !== "undefined") {
      element_.remove()
    } else {
      element_.parentNode?.removeChild(element_)
    }
  }

  if (vNode?.props) {
    vNode.props.onClose = destroy
  }

  return { vNode, destroy, el: element_ }
}
