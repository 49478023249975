<template>
  <HeadlessPopover class="relative">
    <HeadlessPopoverButton
      ref="popoverButtonRef"
      class="rounded-full outline-none focus:outline-none"
    >
      <IconHeroiconsSolidDocumentText />
    </HeadlessPopoverButton>

    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <OnClickOutside>
        <HeadlessPopoverPanel
          ref="popoverRef"
          class="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform cursor-auto px-4 sm:px-0 lg:max-w-lg"
          @click.stop=""
        >
          <div
            class="overflow-hidden rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <p
              class="whitespace-normal break-normal text-sm"
            >
              {{ note }}
            </p>
          </div>
        </HeadlessPopoverPanel>
      </OnClickOutside>
    </Transition>

    <HeadlessPopoverOverlay
      class="bg-background-fg/10 z-1 fixed inset-0"
      @click.stop=""
    />
  </HeadlessPopover>
</template>

<script setup lang="ts">
import { createPopper } from "@popperjs/core"
import type { ComponentPublicInstance } from "vue"

defineProps<{
  note: string
}>()

const popoverButtonRef =
  ref<ComponentPublicInstance | null>(null)
const popoverRef = ref<ComponentPublicInstance | null>(null)

whenever(
  () =>
    popoverButtonRef.value?.$el && popoverRef.value?.$el,
  () => {
    createPopper(
      popoverButtonRef.value?.$el,
      popoverRef.value?.$el,
      {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ],
      }
    )
  }
)
</script>
