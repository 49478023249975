<template>
  <BaseModal @close="onModalClose">
    <template #title>
      Booking - {{ booking?.bookingRef || "" }}

      <BookingStatusChip
        v-if="booking"
        :status="booking?.status"
        class="ml-3"
      />
    </template>

    <div class="mb-8 block">
      <div class="border-b border-gray-200">
        <nav
          class="-mb-px flex space-x-8"
          aria-label="Tabs"
        >
          <template v-for="tab in tabs" :key="tab">
            <a
              :class="[
                tab === activeTab
                  ? 'border-primary '
                  : 'text-background-fg/50 hover:border-background-fg/30 hover:text-background-fg/70 border-transparent',
                'relative cursor-pointer whitespace-nowrap border-b-2 px-2 pb-4 pt-4 text-sm font-medium',
              ]"
              :aria-current="
                tab === 'activeTab' ? 'page' : undefined
              "
              @click="setTab(tab)"
            >
              <template
                v-if="tab === 'Alerts' && booking?.hasAlert"
              >
                <div class="absolute top-0 right-0">
                  <IconHeroiconsSolidExclamationCircle
                    class="text-error"
                  />
                </div>
              </template>
              {{ tab }}
            </a>
          </template>
        </nav>
      </div>
    </div>

    <template v-if="activeTab === 'Details' && booking">
      <BookingForm
        :initial-data="booking"
        @close="onModalClose"
      />
    </template>

    <template v-else-if="activeTab === 'ANPR Log'">
      <CameraEventsTable :filters="cameraEventsFilters" />
    </template>

    <template
      v-else-if="activeTab === 'Actions' && booking"
    >
      <BookingActions :booking="booking" />
    </template>

    <template
      v-else-if="activeTab === 'Message Log' && booking"
    >
      <BookingMessageLog :booking-id="booking.id" />
    </template>

    <template v-else-if="activeTab === 'Alerts' && booking">
      <BookingAlerts :booking-id="booking.id" />
    </template>

    <template v-if="isBookingLoading">
      <BaseContainerLoading tabindex="-1" />
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from "./BaseModal.vue"

const props = defineProps({
  bookingId: {
    type: String,
    required: true,
  },
})
const emit = defineEmits<{ (event: "close"): void }>()
const onModalClose = () => emit("close")
const { data: booking, isFetching: isBookingLoading } =
  useBooking(toRef(props, "bookingId"))

const activeTab = ref("Details")
const setTab = (tab: string) => {
  activeTab.value = tab
}
const tabs = [
  "Details",
  "ANPR Log",
  "Actions",
  "Message Log",
  "Alerts",
]

const cameraEventsFilters = reactive({
  bookingId: props.bookingId,
})
</script>
