import { api } from "@/api"
import { queryClient } from "@/queryClient"

export const deleteAllowlistEntry = async (id: string) => {
  const resp = await api.delete(`/allowlist/${id}/`)
  queryClient.invalidateQueries([
    ALLOWLIST_ENTRIES_QUERY_KEY,
  ])

  return resp
}
