import { api } from "@/api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"
import type { AllowlistEntry } from "./allowlistEntries"

export const ALLOWLIST_ENTRIES_QUERY_KEY =
  "allowlistEntries"

type AllowlistEntryFilters = Partial<{
  search: string
  startsAfter: Date
  startsBefore: Date
  siteId: string | null
}>

type AllowlistEntryQuery = AllowlistEntryFilters &
  PaginationQuery

type AllowlistEntryResponse = Paginate<
  Array<RecursivelyConvertDatesToStrings<AllowlistEntry>>
>

const listAllowlistEntries = async (
  query: AllowlistEntryQuery
) => {
  const { body } = await api.get<AllowlistEntryResponse>(
    "/allowlist",
    { query }
  )

  return {
    ...body,
    items: body.items.map((entryResponse) =>
      parseAllowlistEntry(entryResponse)
    ),
  }
}

export const useAllowlistEntries = (
  query: MaybeRef<AllowlistEntryQuery>
) => {
  const { id: currentSiteId } = useCurrentSite()

  return useQuery({
    queryKey: [
      ALLOWLIST_ENTRIES_QUERY_KEY,
      computed(() => ({
        siteId: currentSiteId.value,
        ...unref(query),
      })),
    ] as const,
    queryFn: ({ queryKey: [_prefix, query_] }) =>
      listAllowlistEntries(unref(query_)),

    refetchIntervalInBackground: false,
    refetchInterval: 60 * 60 * 1000,
  })
}
