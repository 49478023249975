<template>
  <section>
    <h3 class="text-background-fg/87 mb-1 font-semibold"
      >Customer</h3
    >
    <CustomerSearch
      v-if="customer === null"
      @update="$emit('update:customer', $event)"
      @create="popup = true"
    />
    <CustomerCard
      v-else
      :customer="customer"
      :allow-edit="allowEdit"
      @change="$emit('update:customer', null)"
      @edit="popup = true"
    />
    <p v-if="error" class="text-error mt-1 text-xs">
      {{ error }}
    </p>
  </section>
  <BaseModal3 :show="popup" @close="popup = false">
    <template #title>Customer</template>
    <CustomerPopup
      :customer="customer"
      @cancel="popup = false"
      @update="$emit('update:customer', $event)"
    />
  </BaseModal3>
</template>

<script setup lang="ts">
import type { Customer } from "@/services/bookings/customers.js"
import BaseModal3 from "@/modals/BaseModal3.vue"
import { useFormContext } from "@/composables/useFormContext"

const props = defineProps<{
  customer: Customer | null
  name: string
  allowEdit?: boolean
}>()
defineEmits(["update:customer"])

const popup = ref(false)
const error = ref<string | null>(null)

const formContext = useFormContext()
formContext.registerField(props.name, {
  validate() {
    if (props.customer === null) {
      error.value = "Customer required"
      return false
    }
    return true
  },
  resetValidation() {
    error.value = null
  },
  setError(message: string) {
    error.value = message
  },
})

onBeforeUnmount(() =>
  formContext.unregisterField(props.name)
)
</script>
