// Keep in sync with AllowListOutSchema
export type AllowlistEntry = {
  id: string
  siteId: string
  vehicleRegistration: string
  startsAt: Date | null
  endsAt: Date | null
  note: string | null
}

export const parseAllowlistEntry = (
  allowlistEntry: RecursivelyConvertDatesToStrings<AllowlistEntry>
): AllowlistEntry => ({
  ...allowlistEntry,
  startsAt: allowlistEntry.startsAt
    ? DateFns.parseISO(allowlistEntry.startsAt)
    : null,
  endsAt: allowlistEntry.endsAt
    ? DateFns.parseISO(allowlistEntry.endsAt)
    : null,
})
