<template>
  <HeadlessTransitionRoot as="template" :show="isRevealed">
    <HeadlessDialog
      as="div"
      class="relative z-10"
      @close="cancel"
    >
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="bg-background-fg/30 fixed inset-0 transition-opacity"
        />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel
              class="bg-background relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="px-4 pt-5 pb-4">
                <div
                  class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                >
                  <template v-if="title !== undefined">
                    <HeadlessDialogTitle
                      as="h3"
                      class="text-background-fg/70 text-lg font-medium leading-6"
                    >
                      {{ title }}
                    </HeadlessDialogTitle>
                  </template>
                  <div class="mt-2">
                    <p
                      class="text-background-fg/87 text-sm"
                    >
                      {{ description }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                  type="button"
                  :class="[
                    'rounded py-1 px-2 ring-2 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-inherit',
                    type === 'negative'
                      ? 'ring-error text-error hover:bg-error/10 '
                      : type === 'positive'
                      ? 'ring-secondary text-secondary hover:bg-secondary/10 '
                      : 'ring-error text-error hover:bg-error/10 ',
                  ]"
                  @click="confirm"
                >
                  {{ confirmLabel }}
                </button>
                <button
                  type="button"
                  class="ring-background-fg/10 text-background-fg/50 hover:bg-background-fg/3 mr-5 rounded py-1 px-2 ring-2 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-inherit"
                  @click="cancel"
                >
                  {{ cancelLabel }}
                </button>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    confirmLabel?: string
    cancelLabel?: string
    type?: "positive" | "negative" | "info"
  }>(),
  {
    title: undefined,
    description: undefined,
    confirmLabel: "Yes",
    cancelLabel: "No",
    type: "negative",
  }
)
const { reveal, isRevealed, confirm, cancel } =
  useConfirmDialog()

defineExpose({
  reveal,
})
</script>
