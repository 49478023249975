<template>
  <div :class="[disabled ? 'opacity-50' : '', 'text-sm']">
    <BaseInputLabel
      v-if="label"
      :id="`${id_}-label`"
      ref="labelRef"
      :label="label"
      :optional="optional"
      @click="focusInput"
    />
    <slot v-bind="slotProps" />
    <template v-if="hasError">
      <p
        :id="`${id_}-error`"
        class="text-error mt-1 text-xs"
      >
        {{ errorMessage }}
      </p>
    </template>
    <template v-else-if="hint">
      <p
        :id="`${id_}-hint`"
        class="text-background-fg/50 mt-1 text-xs"
      >
        {{ hint }}
      </p>
    </template>
  </div>
</template>

<script lang="ts"></script>

<script setup lang="ts">
import {
  useFormContext,
  type FormField,
} from "@/composables/useFormContext"
import { nanoid } from "nanoid"

// After vue 3.3 we can have input component props extend this
export type BaseInputFieldProps = {
  modelValue?: unknown
  name?: string
  optional?: boolean
  label?: string
  disabled?: boolean
  rules?: Array<(value: any) => boolean | string>
  hint?: string
}

const props = withDefaults(
  defineProps<BaseInputFieldProps>(),
  {
    optional: false,
    disabled: false,
    rules: () => [],
  }
)

const generateInputId = nanoid

const id_ = computed(() => generateInputId())

const inputRef = ref<HTMLInputElement | null>(null)
const focusInput = () => inputRef.value?.focus()

const slotProps = computed(() => ({
  inputRef: inputRef,
  inputProps: inputProps.value,
  hasError: hasError.value,
}))

const hasError = ref(false)
const errorMessage = ref("")

const validate = () => {
  for (const rule of props.rules) {
    const maybeInvalidMessage = rule(props.modelValue)

    if (maybeInvalidMessage !== true) {
      errorMessage.value = maybeInvalidMessage || ""
      hasError.value = true
      return false
    }
  }

  errorMessage.value = ""
  hasError.value = false
  return true
}

const setError = (message: string) => {
  hasError.value = message ? true : false
  errorMessage.value = message
}

watch(
  () => hasError.value,
  (value) => {
    if (!inputRef.value) return
    if (value) {
      inputRef.value.setCustomValidity("invalid")
    } else {
      inputRef.value.setCustomValidity("")
    }
  }
)

const resetValidation = () => {
  errorMessage.value = ""
  hasError.value = false
}

const onInputMaybeValidate = () => {
  if (!hasError.value) return

  validate()
}

const onInput = () => {
  onInputMaybeValidate()
}

const onBlur = () => {
  validate()
}

if (props.name) {
  const formContext = useFormContext()

  const field: FormField = {
    validate,
    resetValidation,
    setError,
  }

  formContext.registerField(props.name, field)

  onBeforeUnmount(
    () =>
      props.name && formContext.unregisterField(props.name)
  )
}

const inputProps = computed(() => ({
  ref: inputRef,
  disabled: props.disabled,
  "aria-invalid": hasError.value,
  "aria-describedby": hasError.value
    ? `${id_.value}-error`
    : props.hint
    ? `${id_.value}-hint`
    : undefined,
  "aria-labelledby": props.label
    ? `${id_.value}-label`
    : undefined,
  onInput: onInput,
  onBlur: onBlur,
}))
</script>
