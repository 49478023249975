<template>
  <BaseInputField
    #default="{ inputProps, hasError }"
    :model-value="modelValue"
    :name="name"
    :optional="optional"
    :label="label"
    :disabled="disabled"
    :rules="rules"
    :hint="hint"
  >
    <div class="relative">
      <textarea
        v-bind="{ ...inputProps }"
        v-model="localModelValue"
        :type="type"
        class="input"
        :class="[
          $slots.after ? 'pr-10' : '',
          inputClass,
          hasError ? 'border-error' : '',
        ]"
        tabindex="0"
        rows="4"
      />

      <slot />
    </div>
  </BaseInputField>
</template>

<script setup lang="ts">
// After vue 3.3 we can have props extend BaseInputFieldProps directly
// rather than nesting
const props = withDefaults(
  defineProps<{
    modelValue: string
    inputClass?: string
    type?: string
    name?: string
    optional?: boolean
    label?: string
    disabled?: boolean
    rules?: Array<(value: string) => boolean | string>
    hint?: string
  }>(),
  {
    type: "text",
    inputClass: "",
  }
)

const emit = defineEmits<{
  (event: "update:modelValue", value: unknown): void
}>()

const localModelValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})
</script>
