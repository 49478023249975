<template>
  <div class="flex items-center space-x-2">
    <span class="text-background-fg/70 text-sm">
      showing
    </span>
    <BaseInputSelect
      :model-value="modelValue"
      :options="options"
      @update:model-value="setModelValue"
    />
    <span class="text-background-fg/70 text-sm">
      items
    </span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: number
}>()

const emit = defineEmits<{
  (
    event: "update:modelValue",
    value: typeof props.modelValue
  ): void
}>()

const setModelValue = (value: number) =>
  emit("update:modelValue", value)
const options = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
] as const
</script>
