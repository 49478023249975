<template>
  <div class="p-4">
    <div class="max-w-120 max-h-90 mb-4 h-screen w-screen">
      <template v-if="camera.liveFeedUrl">
        <button class="relative" @click="toggleLive">
          <!-- eslint-disable-next-line vue/component-name-in-template-casing -->

          <img :src="reloadingImgSource" />
          <template v-if="!isActive">
            <div
              class="bg-background-fg/8 absolute inset-0 flex items-center justify-center"
            >
              <IconHeroiconsSolidPause
                class="text-background h-10 w-10"
              />
            </div>
          </template>
        </button>
      </template>
      <template v-else>
        <div
          class="text-background-fg bg-background-fg/5 flex h-full w-full items-center justify-center"
        >
          <p> No live feed set for this camera </p>
        </div>
      </template>
    </div>
    <template v-if="camera.barrier">
      <div>
        <BarrierControlItem :barrier="camera.barrier" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Camera } from "@/services/cameras/listCameras"
const props = defineProps<{ camera: Camera }>()

const IMAGE_REFRESH_TIME = 5 * 1000
const PLAYER_TIMEOUT = 5 * 60 * 1000

const reloadingImgSource = ref<string>()

const updateReloadingImgSource = () => {
  reloadingImgSource.value = `${
    props.camera.liveFeedUrl
  }&t=${new Date().getTime()}`
}
const { pause, resume, isActive } = useIntervalFn(
  updateReloadingImgSource,
  IMAGE_REFRESH_TIME,
  { immediateCallback: true }
)

const toggleLive = () => {
  if (isActive.value) {
    pause()
  } else {
    start()
    resume()
  }
}

const { start } = useTimeoutFn(pause, PLAYER_TIMEOUT)
</script>
