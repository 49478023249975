import { api } from "@/api"
import type { AllowlistEntry } from "./allowlistEntries"
import { queryClient } from "@/queryClient"
import type { AllowlistFormValues } from "@/components/AllowlistForm.vue"

type CreateAllowlistEntryRequest = {
  siteId: string
  vehicleRegistration: string
  startsAt: Date | null
  endsAt: Date | null
  note: string | null
}

export const createAllowlistEntry = async (
  formValues: AllowlistFormValues
) => {
  const requestBody: CreateAllowlistEntryRequest = {
    ...formValues,
    startsAt: formValues.startsAt
      ? DateFns.parse(
          formValues.startsAt,
          "yyyy-MM-dd'T'HH:mm",
          new Date()
        )
      : null,
    endsAt: formValues.endsAt
      ? DateFns.parse(
          formValues.endsAt,
          "yyyy-MM-dd'T'HH:mm",
          new Date()
        )
      : null,
  }

  const { body } = await api.post<
    RecursivelyConvertDatesToStrings<AllowlistEntry>
  >("/allowlist", {
    body: requestBody,
  })

  queryClient.invalidateQueries([
    ALLOWLIST_ENTRIES_QUERY_KEY,
  ])

  return parseAllowlistEntry(body)
}
