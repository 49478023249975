<template>
  <div class="w-full px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div
        class="flex items-center justify-between sm:flex-auto"
      >
        <h1 class="text-xl font-semibold text-gray-900">
          Allowlist
        </h1>
        <RouterLink
          class="bg-primary text-primary-fg rounded-full px-3 py-2 text-sm"
          :to="{ name: 'createAllowlistEntry' }"
        >
          Add Entry
        </RouterLink>
      </div>
    </div>

    <div class="mt-8 flex flex-col">
      <div>
        <div
          class="mb-4 flex w-full flex-wrap justify-between gap-2"
        >
          <BaseInputSearch v-model="filters.search" />
          <BasePagination
            v-model="page"
            :page-count="pageCount"
            class="ml-auto"
          />
        </div>
        <div
          class="mb-4 flex w-full flex-wrap justify-between gap-2"
        >
          <BaseCountSelect v-model="paginationCount" />
        </div>
        <BaseTable
          key-field="id"
          :rows="rows"
          :columns="columns"
          row-class="cursor-pointer"
          :is-loading="isLoading"
          @click-row="onClickRow"
        >
          <template #body-cell:startsAt="{ value }">
            <p class="w-48 font-semibold text-black">
              {{ formatDatetimeToDatetimeFull(value) }}
            </p>
          </template>

          <template #body-cell:endsAt="{ value }">
            <p class="w-48 font-semibold text-black">
              {{ formatDatetimeToDatetimeFull(value) }}
            </p>
          </template>

          <template #body-cell:note="{ value }">
            <template v-if="value">
              <span class="line-clamp-1">
                {{ value }}
              </span>
            </template>
          </template>

          <template
            #body-cell:vehicleRegistration="{ value }"
          >
            <span>
              <p class="text-md font-bold uppercase">
                {{ value }}
              </p>
            </span>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>

  <RouterView #default="{ Component: component }">
    <BaseModal2 :show="!!component" @close="onModalClose">
      <Component :is="component" @close="onModalClose" />
    </BaseModal2>
  </RouterView>
</template>

<script setup lang="ts">
import type {
  BaseRow,
  ColumnDefinition,
} from "@/types/table"
import type { AllowlistEntry } from "@/services/allowlistEntries/allowlistEntries"
import BaseModal2 from "@/modals/BaseModal2.vue"

const router = useRouter()

const columns: ColumnDefinition<AllowlistEntry>[] = [
  {
    label: "Vehicle Registration",
    name: "vehicleRegistration",
    getValue: (row) => row.vehicleRegistration,
    autoWidth: true,
  },

  {
    label: "Starts At",
    name: "startsAt",
    getValue: (row) => row.startsAt,
    autoWidth: true,
  },

  {
    label: "Ends At",
    name: "endsAt",
    getValue: (row) => row.endsAt,
    autoWidth: true,
  },

  {
    label: "Note",
    name: "note",
    getValue: (row) => row.note,
  },
]

const onClickRow = (row: BaseRow) => {
  router.push({
    name: "allowlistEntryInstance",
    params: {
      allowlistEntryId: row.id,
    },
  })
}

const onModalClose = () => {
  router.push({
    name: "allowlist",
  })
}

const filters = reactive({
  search: "",
})

const paginationCount = ref(10)

const { pagination, page, pageCount, resetPagination } =
  usePagination({
    limit: paginationCount,
    count: computed(() => data.value?.count || 0),
  })

watch(
  () => filters,
  () => resetPagination(),
  { deep: true, immediate: true }
)

const query = reactiveComputed(() => ({
  ...filters,
  ...pagination.value,
}))
const { data, isLoading } = useAllowlistEntries(query)

const rows = computed(() => data.value?.items || [])
</script>
