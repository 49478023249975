import { api } from "@//api"
import { queryClient } from "@/queryClient"
import type { Alert } from "./listAlerts"

export const markAlertAsSeen = async (alert: Alert) => {
  const { body } = await api.post(
    `/alerts/${alert.id}/seen`
  )
  queryClient.invalidateQueries([ALERT_QUERY_KEY])
  queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
  return body
}
