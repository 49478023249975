import type { InjectionKey } from "vue"

export type FormField = {
  validate: () => boolean
  resetValidation: () => void
  setError: (message: string) => void
}

export type FormContext = {
  registerField: (
    fieldName: string,
    field: FormField
  ) => void
  unregisterField: (fieldName: string) => void
}

export type FormSubmitHelpers = {
  setErrors: (errors: Record<string, string>) => void
}

export const FORM_CONTEXT_INJECTION_KEY =
  Symbol() as InjectionKey<FormContext>

export const useFormContext = () => {
  const formContext = inject(FORM_CONTEXT_INJECTION_KEY)

  if (!formContext) {
    throw new Error(
      "'useFormContext' must be used within a child of a <BaseForm /> component"
    )
  }

  return formContext
}
