import { api } from "@/api"

const { setAccessToken, setRefreshToken, refreshToken } =
  useAuth()

type RequestAccessTokenRequest = {
  refreshToken: string
}

type RequestAccessTokenResponse = {
  accessToken: string
  refreshToken: string
}

export const refreshAccessToken = async () => {
  const requestBody: RequestAccessTokenRequest = {
    refreshToken: refreshToken.value,
  }

  const { body } =
    await api.post<RequestAccessTokenResponse>(
      "/auth/refresh-token/",
      {
        body: requestBody,
        authenticated: false,
      }
    )

  setAccessToken(body.accessToken)
  setRefreshToken(body.refreshToken)

  return body
}
