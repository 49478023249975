import { api } from "@//api"
import { useQuery } from "@tanstack/vue-query"
import type { MaybeRef } from "@vueuse/core"

export const BOOKING_MESSAGES_QUERY_KEY = "bookingMessages"

export type BookingMessage = {
  id: string
  direction: "incoming" | "outgoing"
  content: string
  createdAt: Date
}

export const listBookingMessages = async (
  bookingId: string
): Promise<BookingMessage[]> => {
  const { body } = await api.get<{
    messages: RecursivelyConvertDatesToStrings<
      BookingMessage[]
    >
  }>(`/bookings/${bookingId}/messages`)

  return body.messages.map((message) => ({
    ...message,
    createdAt: DateFns.parseISO(message.createdAt),
  }))
}

export const useBookingMessages = (
  bookingId: MaybeRef<string>
) =>
  useQuery({
    queryKey: [BOOKING_MESSAGES_QUERY_KEY, bookingId],
    queryFn: ({ queryKey: [_prefix, bookingId_] }) =>
      listBookingMessages(unref(bookingId_)),

    refetchIntervalInBackground: false,
    refetchInterval: 60 * 1000,
  })
