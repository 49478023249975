import type { BookingForm } from "./bookingForm"
import type { BookingType } from "./bookingTypes"

// Keep in sync with VehicleBookingInSchema
type CreateUpdateBookingVehicleBooking = {
  id: string | null // null to create
  registration: string
  isPrimary: boolean | null
}

// Keep in sync with BookingInSchema
export type CreateUpdateBookingRequestBody = {
  bookingRef: string
  startsAt: string
  endsAt: string
  siteId: string
  pitchType: string
  pitchNumber: string | null
  bookingType: BookingType
  note: string | null
  balanceDue: number
  autoBalance: boolean
  customer_id: string | null
  vehicleBookings: Array<CreateUpdateBookingVehicleBooking>
}

export const parseBookingForm = (
  form: BookingForm
): CreateUpdateBookingRequestBody => {
  const {
    primaryVehicleBooking,
    vehicleBookings,
    ...newForm
  } = form

  return {
    ...newForm,
    customer_id: newForm.customer?.id ?? null,
    siteId: newForm.siteId ?? "",
    pitchType: newForm.pitchType ?? "",
    vehicleBookings: [
      { ...primaryVehicleBooking, isPrimary: true },
      ...vehicleBookings.map((vehicleBooking) => ({
        ...vehicleBooking,
        isPrimary: false,
      })),
    ],
  }
}
