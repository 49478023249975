<template>
  <div>
    <label
      class="text-background-fg/87 mb-2 block text-xs font-semibold"
    >
      {{ label }}
    </label>
    <div class="no-wrap flex">
      <BaseInputText
        v-model="dateModel"
        type="date"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        input-class="text-xs"
      />
      <BaseInputSelect
        v-model="timeModel"
        :options="timeOptions"
        input-class="text-xs"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const timeOptions = new Array(24)
  .fill(undefined)
  .map((_, index) => {
    const value = `${String(index).padStart(2, "0")}:00`
    return {
      value: value,
      label: value,
    }
  })

const props = defineProps<{
  modelValue: string | undefined
  label?: string
}>()

const emit = defineEmits<{
  (
    event: "update:modelValue",
    value: typeof props.modelValue
  ): void
}>()

const dateModel = ref<string>("")
const timeModel = ref<string>()

whenever(
  () => props.modelValue,
  (value) => {
    const [date, time] = value.split("T")
    dateModel.value = date
    timeModel.value = time
  },
  {
    immediate: true,
  }
)

const fullModel = computed(() => {
  if (dateModel.value && timeModel.value) {
    return `${dateModel.value}T${timeModel.value}`
  } else {
    return undefined
  }
})

watch(
  () => fullModel.value,
  (value) => {
    emit("update:modelValue", value)
  }
)
</script>
