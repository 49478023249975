import type { ApiResponse } from "@/api"
import download from "downloadjs"

const getFileNameFromResponse = (
  data: ApiResponse<Blob>
) => {
  const dispositionHeader = new Map(
    Object.entries(data.headers)
  ).get("content-disposition")
  const filename = dispositionHeader
    ?.split(/;(.+)/)[1]
    .split(/=(.+)/)[1]
  return filename
}

export const exportFileFromRespone = (
  data: ApiResponse<Blob>
) => {
  const filename = getFileNameFromResponse(data)
  download(data.body, filename)
}
