<template>
  <BaseForm class="space-y-4" @submit="onSubmit">
    <section class="grid grid-cols-4 gap-4">
      <BaseInputSelect
        v-model="formValues.siteId"
        :options="siteOptions"
        class="col-span-4 sm:col-span-2"
        label="Site"
        name="siteId"
        :rules="rules?.siteId"
      />
      <BaseInputText
        v-model="formValues.vehicleRegistration"
        class="col-span-4 sm:col-span-2"
        label="Vehicle Registration"
        name="vehicleRegistration"
      />

      <BaseInputDatetime
        v-model="formValues.startsAt"
        label="Starts At"
        class="col-span-4 sm:col-span-2"
        name="startsAt"
      />
      <BaseInputDatetime
        v-model="formValues.endsAt"
        label="Ends At"
        class="col-span-4 sm:col-span-2"
        name="endsAt"
      />
    </section>

    <section class="grid grid-cols-4 gap-4">
      <BaseInputTextarea
        v-model="formValues.note"
        label="Note"
        class="col-span-4"
        name="note"
      />
    </section>

    <div class="grid w-full grid-cols-4 gap-4">
      <button
        v-if="initialData?.id"
        type="button"
        class="bg-error col-span-4 rounded-md px-4 py-2 text-sm text-white sm:col-span-1 sm:col-start-1"
        @click="onDeleteClick"
      >
        Delete Allowlist Entry
      </button>
      <button
        class="bg-background-fg/30 col-span-4 rounded-md px-4 py-2 text-sm text-white sm:col-span-1 sm:col-start-3"
        type="button"
        @click="onCancel"
      >
        Cancel
      </button>

      <button
        type="submit"
        class="bg-primary text-primary-fg col-span-4 rounded-md px-4 py-2 text-sm sm:col-span-1 sm:col-start-4"
      >
        Save Allowlist Entry
      </button>
    </div>

    <template v-if="isSubmitting">
      <BaseContainerLoading />
    </template>
  </BaseForm>

  <BaseConfirmation
    ref="deleteConfirmDialogRef"
    description="Are you sure you wish to delete this allowlist entry?"
    confirm-label="Delete Allowlist Entry"
    cancel-label="Cancel"
  />
</template>

<script setup lang="ts">
import type { AllowlistEntry } from "@/services/allowlistEntries/allowlistEntries"
import type { FormSubmitHelpers } from "@/composables/useFormContext"
import { ApiError } from "@/api"
import BaseConfirmation from "@/components/BaseConfirmation.vue"

export type AllowlistFormValues = {
  siteId: string
  vehicleRegistration: string
  startsAt: string
  endsAt: string
  note: string
}

const props = defineProps<{
  initialData?: AllowlistEntry
}>()

const emit = defineEmits<{
  (event: "cancel"): void
}>()
const onCancel = () => emit("cancel")

const router = useRouter()

const formValues = reactive<AllowlistFormValues>(
  props.initialData
    ? {
        siteId: props.initialData.siteId,
        vehicleRegistration:
          props.initialData.vehicleRegistration,
        startsAt: props.initialData.startsAt
          ? DateFns.format(
              props.initialData.startsAt,
              "yyyy-MM-dd'T'HH:mm"
            )
          : "",
        endsAt: props.initialData.endsAt
          ? DateFns.format(
              props.initialData.endsAt,
              "yyyy-MM-dd'T'HH:mm"
            )
          : "",
        note: props.initialData.note ?? "",
      }
    : {
        siteId: "",
        vehicleRegistration: "",
        startsAt: "",
        endsAt: "",
        note: "",
      }
)

const { data: sites } = useSites()
const siteOptions = computed(
  () =>
    sites.value?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) || []
)

const isSubmitting = ref(false)
const onSubmit = async (helpers: FormSubmitHelpers) => {
  isSubmitting.value = true

  try {
    if (props.initialData?.id) {
      await updateAllowlistEntry(
        props.initialData.id,
        formValues
      )
      toast.success("Allowlist entry updated")
    } else {
      await createAllowlistEntry(formValues)
      toast.success("Allowlist entry created")
    }
    router.push({ name: "allowlist" })
  } catch (error) {
    if (error instanceof ApiError) {
      const bodyErrors = error.requestErrors?.body
      bodyErrors && helpers.setErrors(bodyErrors)
    }

    if (props.initialData?.id) {
      toast.error("Failed to update allowlist entry")
    } else {
      toast.error("Failed to create allowlist entry")
    }
  } finally {
    isSubmitting.value = false
  }
}

const required = (value: unknown) =>
  !!value || "Field is required"

const rules = reactive({
  siteId: [required],
  vehicleRegistration: [required],
})

const deleteConfirmDialogRef = ref<InstanceType<
  typeof BaseConfirmation
> | null>(null)
const isDeleting = ref(false)
const onDeleteClick = async () => {
  if (
    !props.initialData?.id ||
    deleteConfirmDialogRef.value == null
  )
    return
  const { isCanceled: isDeleteCancelled } =
    await deleteConfirmDialogRef.value.reveal()
  if (isDeleteCancelled) return

  isDeleting.value = true
  try {
    await deleteAllowlistEntry(props.initialData?.id)
    toast.success("Allowlist entry deleted")
    router.push({ name: "allowlist" })
  } catch (e) {
    toast.error("Failed to delete allowlist entry")
    throw e
  } finally {
    isDeleting.value = false
  }
}
</script>
