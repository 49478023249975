const datePickerModel = ref({
  start: DateFns.startOfToday(),
  end: DateFns.endOfToday(),
})

export const useDatePickerContext = () => {
  return {
    datePickerModel,
  }
}
