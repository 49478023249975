<template>
  <div class="px-8">
    <ol
      class="max-h-112 mb-8 flex flex-col-reverse space-y-4 overflow-auto pr-4"
    >
      <template
        v-for="message in messages"
        :key="message.id"
      >
        <li>
          <div
            :class="
              message.direction === 'incoming'
                ? 'pr-20 text-left'
                : 'pl-20 text-right'
            "
          >
            <p :class="['text-primary mb-2 font-medium']">
              {{ formatShortDateTime(message.createdAt) }}
            </p>
            <p>
              {{ message.content }}
            </p>
          </div>
        </li>
      </template>
    </ol>
    <section>
      <BaseForm
        class="relative space-y-4"
        @submit="onSendSMSSubmit"
      >
        <BaseInputTextarea
          v-model="customSMSContent"
          :rules="customSMSContentRules"
          name="customSMSContent"
        />
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-4">
          <button
            class="ring-secondary text-secondary hover:bg-secondary/10 rounded py-1 px-2 ring-2 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-inherit sm:col-start-4"
            type="submit"
          >
            Send SMS
          </button>
        </div>

        <template v-if="isCustomSMSSending">
          <BaseContainerLoading />
        </template>
      </BaseForm>
    </section>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ bookingId: string }>()

const { data: messages, refetch: refetchMessages } =
  useBookingMessages(toRef(props, "bookingId"))

const formatShortDateTime = (date: Date) => {
  return DateFns.format(date, "dd MMM, HH:mm")
}

const customSMSContent = ref("")
const isCustomSMSSending = ref(false)
const customSMSContentRules = [
  (value: string) =>
    !!value || "Please enter sms message content",
]
const onSendSMSSubmit = async () => {
  isCustomSMSSending.value = true
  try {
    await sendBookingSms(
      props.bookingId,
      customSMSContent.value
    )

    toast.success("Sent SMS")
    customSMSContent.value = ""
  } catch {
    toast.error("Failed to send SMS")
  } finally {
    isCustomSMSSending.value = false
    refetchMessages()
  }
}
</script>
